import React from 'react';
import ListItem from '../ListItem/ListItem';
import './Lists.css';
import Pagination from '../Pagination/Pagination';

function Lists(props) {
    const { items=[], items_per_page=10, total=0, category_str='people', search_str=null } = props;

    return (
        <div className={"lists"}>
            {
                items.length !== 0 && total !== 0 
                ?
                    <>
                        <Pagination data={props} showText={false} showPagination={false} path={`/search/${category_str}/PAGE_NO/${items_per_page}/${search_str}`} />
                        <ul>
                            {
                                items.map((item, index) => {
                                    return (
                                        <li key={`ListItem-${index}`}>
                                            <ListItem item={item} />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <Pagination data={props} showTextHeader={false} path={`/search/${category_str}/PAGE_NO/${items_per_page}/${search_str}`} />
                    </>
                :
                    <h3>No Items Found...please try again</h3>
            }
        </div>
    )
}
  
export default Lists;