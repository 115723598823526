import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { AbsoluteLink } from '../utils/LinkUtils';

import RegistrationForm from '../components/RegistrationForm/RegistrationForm.2'; //version 2 has verification
import './Registration.css';

import { GlobalContext } from "../contexts/GlobalContext";

function Registration() {
    const navigate = useNavigate();
    const { GlobalContextState } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null } = GlobalContextState;

    if (!global_session && global_session_key === null && account === null)  {
        return (
            <div className="page registration-page">
                <Helmet>
                    <title>Project Alpha - Registration</title>
                    <meta name="description" content="Registartion page descirption" />
                    <link rel="canonical" href={AbsoluteLink({ path: '/registration' })} />
                </Helmet>
                <h1>Registration</h1>
                <div className={"registration-wrapper"}>
                    <RegistrationForm />
                </div>
            </div>
        )
    } else {
        navigate(`/search`)
    }
}

export default Registration;