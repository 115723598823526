import React from 'react';
import AppRoutes from './routes/AppRoutes'
import './App.css';
import GlobalContextProvider from './contexts/GlobalContext';

function App(props) {
  const { context=null } = props;
  // console.log('App context', context)
  return (
    <GlobalContextProvider>
      <div className="app">
        <AppRoutes context={context} />
      </div>
    </GlobalContextProvider>
  )
}

export default App;