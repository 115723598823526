import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { ValidateStringNotEmpty } from '../../utils/ValidationUtils';
import './SearchForm.css';

function SearchForm() {
    const navigate = useNavigate();
    const { categoryStr='people', pageNo=1, searchStr='' } = useParams();
    console.log('SearchForm categoryStr', categoryStr)
    console.log('SearchForm pageNo', pageNo)
    console.log('SearchForm searchStr', searchStr)

    const [ searchData, setSearchData ] = useState(searchStr.trim().replaceAll('-',' '));
    const [ searchDataError, setSearchDataError ] = useState(false);
    const [ submit, setSubmit ] = useState(false);

    const handleOnSubmit = (e) => {
        console.log(e)
        setSubmit(true);
        try {
            e.preventDefault();
            if (ValidateStringNotEmpty(searchData)) {
                setSearchDataError(false);
                navigate(`/search/${categoryStr}/1/10/${searchData.trim().replace(/[^a-zA-Z0-9 ]/g, '').replaceAll(' ','-')}`);
            } else {
                setSearchDataError(true);
            }
        } catch (err) {
            setSubmit(false);
            console.log("handleOnSubmit", err)
        }
    }

    useEffect(() => {
        if (submit && searchData !== '') {
            setSubmit(false);
        }
    }, [searchData])

    return (
        <div className={"search-form"}>
            <form method={'get'} onSubmit={(e) => handleOnSubmit(e)}>
                <div className={"input-wrapper"}>
                    <input type={"text"} name={"search_data"} placeholder={"Search"} value={searchData} onChange={(e) => setSearchData(e.target.value)} className={searchDataError ? 'error' : ''} />
                    <label>{"Search"}</label>   
                </div>
                <div className={"input-wrapper"}>
                    <input type={"submit"} value={"Submit"} disabled={submit  ? 'disabled' : ''} />
                </div>
            </form>
        </div>
    )
}
  
export default SearchForm;