import React from 'react';
import FullScreenVideo from '../FullScreenVideo/FullScreenVideo';
import './FullScreenVideoList.css'; // Importing the CSS for this component

const FullScreenVideoList = ({ videoList }) => {
  return (
    <div className="videoListContainer">
      {videoList.map((videoSrc, index) => (
        <FullScreenVideo key={index} src={videoSrc} />
      ))}
    </div>
  );
};

export default FullScreenVideoList;