import React from 'react';
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import { AbsoluteLink } from "../utils/LinkUtils";
import './ServerError.css';

function ServerError() {
    return (
        <div className="page server-error">
            <Helmet>
                <title>Project Alpha - Technical Difficulties</title>
                <meta name="description" content="We are having technical difficulties." />
                <link rel="canonical" href={AbsoluteLink({path: "/server-error"})} />
            </Helmet>
            <h1>We are having technical difficulties and are actively working on a fix...</h1>
            <div className={"server-error-wrapper"}>
               
            </div>
        </div>
    );
  }
  
  export default ServerError;