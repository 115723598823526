import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import Item from '../components/Item/Item';
import { ItemContext } from '../contexts/ItemContext';
import { ItemAbsoluteLink } from '../utils/LinkUtils';
import './ItemPage.css';

import PageNotFound from './PageNotFound';
import ServerError from './ServerError';

function ItemPage() {
    const { ItemContextState } = useContext(ItemContext);
    const { item, page_not_found } = ItemContextState;
    const { _id=null, profileName=null, profileAka=null, profileLocation=null, profilePastLocation=null } = item;
    
    if (_id !== null) {
        return (
            <div className={"page item-page"}>
                <Helmet>
                    <title>Project Alpha - {profileName}</title>
                    <meta name="description" content={`${profileName} descriprion section. Other names ${profileAka}. Current location(s)${profileLocation} Past location(s) ${profilePastLocation}`} />
                    <link rel="canonical" href={ItemAbsoluteLink({ item_id: _id, item_name: profileName })} />
                </Helmet>
                <div className={"item-wrapper"}>
                    <Item item={item} />
                </div>
            </div>
        )
    } else if (page_not_found) {
        return ( <PageNotFound /> )
    } else if (page_not_found) {
        return ( <ServerError /> )
    } else {
        return ( null )
    }

}

export default ItemPage;