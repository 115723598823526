import React from 'react';
import { Helmet } from "react-helmet";
import { AbsoluteLink } from '../utils/LinkUtils';
import './Faqs.css';

function Faqs() {
    return (
        <div className={"page faqs-page"}>
            <Helmet>
                <title>Project Alpha - FAQs</title>
                <meta name="description" content="FAQs descirption" />
                <link rel="canonical" href={AbsoluteLink({ path: '/faqs' })} />
            </Helmet>
            <h1>FAQs</h1>
            <div className={"faqs-wrapper"}>
               
            </div>
        </div>
    )
}

export default Faqs;