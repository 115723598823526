export const GlobalState = {
    global_initial_load: true,
    global_page_not_found: false,
    global_server_error: false,
    global_session: false,
    global_session_redirect: false,
    global_session_key: null,
    context: null,
    account: null,
    user_info_update: null,
    device_remove: null,
    process_message_show: false,
    process_message: null,
    show_navigation: false,
    show_footer: false
}