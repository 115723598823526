export const ItemState = {
    item: {
        id : null,
        profileName: null,
        profileAge: null,
        profileAka: null,
        profilePastLocation: null,
        profileLink: null,
        likeCount: 0,
        paidCount: 0,
        isLike: false,
        isPaid: false
    },
    initial_load: true,
    page_not_found: false,
    server_error: false
}