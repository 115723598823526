import React, { createContext, useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { GlobalReducer } from "./GlobalReducer";
import { GlobalState } from "./GlobalState";
import { CheckSession } from "../services/RegisterServices";
import { UpdateUserInfo, RemoveUserDevice } from "../services/UserServices";

export const GlobalContext = createContext();
const initialState = GlobalState;

const GlobalContextProvider = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // console.log('GlobalContextProvider props', props)
    // console.log('GlobalContextProvider initialState', initialState)
    const [GlobalContextState, GlobalContextDispatch] = useReducer(GlobalReducer, initialState);
    const { global_session_redirect = false, global_session = false, global_session_key = null, user_info_update = null, device_remove = null } = GlobalContextState;
    
    useEffect(() => {
        GlobalContextDispatch({ type: "SHOW_PROCESS_MESSAGE", payload: { process_message: 'Loading . . .' } });
        CheckSession().then(result => {
            if (result.data.success === 1) {
                // console.log('result.data.session', result.data.session)
                GlobalContextDispatch({ type: "UPDATE_SESSION", payload: { token: result.data.token, session: true, account: result.data.account } });
            } else if (result.data.success === 0) {
                GlobalContextDispatch({ type: "UPDATE_SESSION", payload: { token: null, session: false, account: null } });
                // enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
            } 
            GlobalContextDispatch({ type: "HIDE_PROCESS_MESSAGE" });   
        }).catch(err => {
            console.log('GlobalContextProvider err', err)
            if (err.response.data) {
                if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                    // enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                }    
            }
            GlobalContextDispatch({ type: "HIDE_PROCESS_MESSAGE" });
        });
    }, []);

    // useEffect(() => {
    //     if (global_session && global_session_key && global_session_redirect) {
    //         navigate(`/search`)
    //     }
    // }, [global_session])

    useEffect(() => {
        if (global_session && global_session_key && user_info_update) {
            console.log('user_info_update', user_info_update)
            UpdateUserInfo(user_info_update, global_session_key).then(result => {
                if (result.data.success === 1) {
                    enqueueSnackbar(result.data.message, { variant: 'info', autoHideDuration: 3000 });
                } else if (result.data.success === 0) {
                    enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                } 
                GlobalContextDispatch({ type: "RESET_USER_INFO_UPDATE" });   
            }).catch(err => {
                console.log('GlobalContextProvider err', err)
                GlobalContextDispatch({ type: "RESET_USER_INFO_UPDATE" });
                if (err.response.data) {
                    if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }    
                }
            });
        }
    }, [user_info_update])

    useEffect(() => {
        if (global_session && global_session_key && device_remove) {
            console.log('device_remove', device_remove)
            RemoveUserDevice(device_remove, global_session_key).then(result => {
                if (result.data.success === 1) {
                    enqueueSnackbar(result.data.message, { variant: 'info', autoHideDuration: 3000 });
                } else if (result.data.success === 0) {
                    enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                } 
                GlobalContextDispatch({ type: "RESET_DEVICE_REMOVE" });   
            }).catch(err => {
                console.log('GlobalContextProvider err', err)
                GlobalContextDispatch({ type: "RESET_DEVICE_REMOVE" });
                if (err.response.data) {
                    if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }    
                }
            });
        }
    }, [device_remove])

    return (
        <GlobalContext.Provider value={{GlobalContextState, GlobalContextDispatch}}>
            {props.children}
        </GlobalContext.Provider>
    );
}

export default GlobalContextProvider;