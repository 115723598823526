export const RegistrationMessageConstant = {
    en : {
        invalidEmailAddresError: 'Invalid email address',
        invalidPasswordError: 'Invalid password',
        confirmationPasswordError: 'Confirmation password does not match'
    }
}

export const LoginMessageConstant = {
    en : {
        passwordRequiredError: 'Password is required',
        emailRequiredError: 'Email is required'
    }
}

export const AccountMessageConstant = {
    en : {
        passwordRequiredError: 'Password is required',
        emailRequiredError: 'Email is required',
        invalidEmailAddresError: 'Invalid email address',
        invalidPasswordError: 'Invalid password',
        confirmationPasswordError: 'Confirmation password does not match'
    }
}



