import React from 'react';
import './Devices.css';
import DeviceInfo from '../DeviceInfo/DeviceInfo';

function Devices(props) {
    // console.log('Devices', props);
    const { devices=[] } = props;

    return (
        <>
            {
                devices.length !== 0
                ?
                    devices.map((item, index) => {
                        return <DeviceInfo device={item} index={index} />
                    })
                :
                    null
            }
        </>
    )
    
}
  
export default Devices;