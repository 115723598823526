import React from 'react';
import { Helmet } from "react-helmet";
import { AbsoluteLink } from '../utils/LinkUtils';
import './Terms.css';

function Faqs() {
    return (
        <div className={"page terms-page"}>
            <Helmet>
                <title>Project Alpha - Terms and Conditions</title>
                <meta name="description" content="Terms and Conditions descirption" />
                <link rel="canonical" href={AbsoluteLink({ path: '/faqs' })} />
            </Helmet>
            <h1>Terms, Agreements, and Conditions</h1>
            <div className={"terms-wrapper"}>
                <h2>1. Use of Content</h2>
                <p>a. All content provided on this blog site is for informational purposes only. The views and opinions expressed
                    in the blog posts are those of the authors and do not necessarily reflect the official policy or position of the
                    blog site.</p>
                <p>b. The content on this blog site is protected by copyright and intellectual property laws. You may not modify,
                    distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer or
                    sell any information or content obtained from this blog site.</p>

                <h2>2. User Conduct</h2>
                <p>a. You are solely responsible for your use of the blog site and agree to use it in accordance with all applicable
                    laws and regulations.</p>
                <p>b. You agree not to engage in any of the following activities:</p>
                <ul>
                    <li>i. Posting, transmitting, or otherwise making available any content that is unlawful, harmful, threatening,
                        abusive, harassing, defamatory, obscene, libelous, invasive of another's privacy, or otherwise
                        objectionable.</li>
                    <li>ii. Impersonating any person or entity, including but not limited to, a blog site administrator, author, or
                        other users.</li>
                    <li>iii. Posting or transmitting unauthorized commercial communications (such as spam) on the blog site.</li>
                    <li>iv. Interfering with or disrupting the blog site or servers or networks connected to the blog site, or
                        disobeying any requirements, procedures, policies, or regulations of networks connected to the blog site.
                    </li>
                </ul>

                <h2>3. Disclaimer</h2>
                <p>a. The information provided on this blog site is for general informational purposes only. It is not intended as
                    professional or legal advice. You should not rely on the information provided on this blog site as a substitute
                    for professional advice. Always seek the advice of a qualified professional regarding any specific issues or
                    concerns you may have.</p>
                <p>b. The blog site makes no representations or warranties of any kind, express or implied, about the accuracy,
                    reliability, suitability, or availability of the information contained on the site or the related graphics. Any
                    reliance you place on such information is strictly at your own risk.</p>
                <p>c. The blog site does not guarantee the availability, accuracy, or completeness of the information provided on
                    the site. The blog site may contain technical inaccuracies or typographical errors.</p>
                <p>d. The blog site reserves the right to modify, update, or remove any content or services provided on the site
                    without prior notice.</p>

                <h2>4. Links to Third-Party Websites</h2>
                <p>a. The blog site may contain links to third-party websites or resources. These links are provided for your
                    convenience and do not imply endorsement or affiliation with the linked site. The blog site has no control over
                    the content or availability of these external sites and is not responsible for their content, privacy practices,
                    or availability.</p>

                <h2>5. Limitation of Liability</h2>
                <p>a. In no event shall the blog site or its owners, administrators, authors, or affiliates be liable for any
                    direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the
                    use of the blog site or the content contained therein.</p>
                <p>b. The blog site shall not be liable for any loss or damage caused by viruses, distributed denial-of-service
                    attacks, or other technologically harmful material that may infect your computer equipment, computer programs,
                    data, or other proprietary material due to your use of the blog site or any third-party websites linked to
                    it.</p>

                <h2>6. Indemnification</h2>
                <p>You agree to indemnify, defend, and hold harmless the blog site and its owners, administrators, authors, and
                    affiliates from any and all claims, liabilities, damages, costs, and expenses, including reasonable attorneys'
                    fees, arising out of or in any way related to your use of the blog site or violation of these terms and
                    conditions.</p>

                <h2>7. Governing Law</h2>
                <p>These terms and conditions shall be governed by and construed in accordance with the laws of [Insert relevant
                    jurisdiction]. Any disputes arising under or in connection with these terms and conditions shall be subject to
                    the exclusive jurisdiction of the courts in [Insert relevant jurisdiction].</p>

                <p>By using this blog site, you acknowledge that you have read, understood, and agree to be bound by these terms and
                    conditions.</p>
            </div>
        </div>
    )
}

export default Faqs;