import './ReactionCameraActions.css';
import React, { useContext, useRef, useState, useEffect } from "react";

import { ReactionCameraContext } from '../../contexts/ReactionCameraContext';

function ReactionCameraActions() {

  const { ReactionCameraContextState, ReactionCameraCotextDispatch } = useContext(ReactionCameraContext);
  const { isRecording, isFaceMode } = ReactionCameraContextState;

  const handleClickStopRecording = (e) => { 
    console.log('handleClickStopRecording', e, typeof e)   
    ReactionCameraCotextDispatch({ type: "SET_STOP_RECORDING" });
  }

  const handleClickPost = (e) => {
    console.log('handleClickPost', e, typeof e)   
    ReactionCameraCotextDispatch({ type: "SET_POSTING", payload: { isPosting: true } });
  }

  const handleToggleFaceMode = (e) => {
    console.log('handleToggleFaceMode', e, typeof e)   
    ReactionCameraCotextDispatch({ type: "SET_FACE_MODE", payload: { isFaceMode: !isFaceMode } });
  }

  // const videoRef = useRef(null);
  // const canvasRef = useRef(null);
  // const [streaming, setStreaming] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);
  // const [height, setHeight] = useState(window.innerHeight);
  // const [showCanvas, setShowCanvas] = useState(false);

  // useEffect(() => {
  //   function handleResize() {
  //     if (streaming && videoRef.current && canvasRef.current) {
  //       const aspectRatio = videoRef.current.videoHeight / videoRef.current.videoWidth;
  //       setWidth(window.innerWidth);
  //       setHeight(window.innerWidth * aspectRatio);
  //       canvasRef.current.width = width;
  //       canvasRef.current.height = height;
  //     }
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [streaming, width, height]);

  // const startVideo = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     videoRef.current.srcObject = stream;
  //     videoRef.current.play();
  //   } catch (error) {
  //     console.error("Error opening video camera.", error);
  //   }
  // };

  // const stopVideo = () => {
  //   const stream = videoRef.current.srcObject;
  //   const tracks = stream.getTracks();

  //   tracks.forEach(function (track) {
  //     track.stop();
  //   });

  //   videoRef.current.srcObject = null;
  // };

  // const captureImage = () => {
  //   if (canvasRef.current && videoRef.current) {
  //     const ctx = canvasRef.current.getContext("2d");
  //     ctx.drawImage(videoRef.current, 0, 0, width, height);
  //     setShowCanvas(true);
  //   }
  // };

  // const handleCanPlay = () => {
  //   if (!streaming) {
  //     const aspectRatio = videoRef.current.videoHeight / videoRef.current.videoWidth;
  //     setWidth(window.innerWidth);
  //     setHeight(window.innerWidth * aspectRatio);

  //     if (videoRef.current && canvasRef.current) {
  //       videoRef.current.width = width;
  //       videoRef.current.height = height;
  //       canvasRef.current.width = width;
  //       canvasRef.current.height = height;

  //       setStreaming(true);
  //     }
  //   }
  // };

  return (
    <>
      {
        isRecording
        ?
          <div className="item-action" onClick={(e) => handleClickStopRecording(e)}>
            <div className="item-icon">

            </div>
            <div className="item-text">
              STOP
            </div>
          </div>
        :
          <div className="item-action" onClick={(e) => handleClickPost(e)}>
            <div className="item-icon">

            </div>
            <div className="item-text">
              POST
            </div>
          </div>
      }
      <div className="item-action">
        <div className="item-icon">

        </div>
        <div className="item-text">
          REDO
        </div>
      </div>
      <div className="item-action">
        <div className="item-icon">

        </div>
        <div className="item-text" onClick={((e) => handleToggleFaceMode(e))}>
          FLIP
        </div>
      </div>
    </>
  );
}

export default ReactionCameraActions;
