import React from 'react';
import { Helmet } from "react-helmet";
import './MagicCardPage.css';
import MagicCardApp from '../components/MagicCard/MagicCardApp';
import { AbsoluteLink } from '../utils/LinkUtils';

function MagicCardPage() {
    return (
        <div className={"page magic-card-page"}>
            <Helmet>
                <title>Mystical Card Quest: A Journey Through Numbers and Suits</title>
                <meta name="description" content="A playful and mysterious adventure involving playing cards, appealing to the curiosity and imagination of participants as they engage with the questions." />
                <link rel="canonical" href={AbsoluteLink({ path: '/magic-card' })} />
            </Helmet>
            {/* <h1>Magic Card</h1> */}
            <div className={"magic-card-wrapper"}>
                <h1>Mystical Card Quest<span>A Journey Through Numbers and Suit</span></h1>
                <MagicCardApp />
            </div>
        </div>
    )
}

export default MagicCardPage;