import axios from 'axios';
const forgotPasswordApiVersion = process.env.REACT_APP_VERSION_API || '1.0';

export async function ForgotPassword(objData) {
    let endpoint = `/api/${forgotPasswordApiVersion}/user/forgot-password`;
    const data = await axios.post(endpoint, objData);
    return data;
}

// export async function ForgotPassword(objData) {
//     let endpoint = `/api/${forgotPasswordApiVersion}/user/forgot-password`;
//     const data = await axios.post(endpoint, objData);
//     return data;
// }