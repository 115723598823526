import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";
import './Footer.css';

function Footer() {

    const { GlobalContextState } = useContext(GlobalContext);
    const { show_footer = false } = GlobalContextState;
    const [ showFooter, setShowFooter ] = useState(false || show_footer);

    useEffect(() => {
        if (showFooter !== show_footer) {
            setShowFooter(show_footer)
        }
    }, [show_footer])

    return (
        <>
            {
                showFooter
                ?
                    <footer>
                        <p>&copy; 2023 Your Company. All rights reserved. | <Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms of Service</Link></p>
                    </footer>
                :
                    null
            }
        </>
    )
}
  
export default Footer;