import axios from 'axios';
const registerApiVersion = process.env.REACT_APP_VERSION_API || '1.0';

export async function Register(objData) {
    let endpoint = `/api/${registerApiVersion}/user/register`;
    const data = await axios.post(endpoint, objData);
    return data;
}

export async function CheckSession() {
    let endpoint = `/api/${registerApiVersion}/user/session`;
    const data = await axios.get(endpoint);
    return data;
}

