import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { AbsoluteLink } from '../utils/LinkUtils';
import ForgotPasswordForm from '../components/ForgotPasswordForm/ForgotPasswordForm'; 
import './ForgotPassword.css';

function ForgotPassword() {
    return (
        <div className="page forgot-password-page">
            <Helmet>
                <title>Project Alpha - Forgot Password</title>
                <meta name="description" content="Forgot password page descirption" />
                <link rel="canonical" href={AbsoluteLink({ path: '/forgot-password' })} />
            </Helmet>
            <h1>Forgot Password?</h1>
            <div className={"forgot-password-wrapper"}>
                <ForgotPasswordForm />
            </div>
        </div>
    )
}

export default ForgotPassword;