export const MagicCardReducer = (state, action) => {
    switch(action.type){
        case 'SET_UP_MAGIC_CARD': 
            return {
                ...state,
                magic_card_setup: action.payload.cards
            } 
        case 'SET_UP_SHUFFLE': 
            return {
                ...state,
                shuffle: action.payload.shuffle,
                selected_suit: action.payload.selected_suit 
            }   
        case 'RESET_UP_SHUFFLE': 
            return {
                ...state,
                shuffle: false,
                selected_suit: null
            }      
        default:
            return state    
    }
}