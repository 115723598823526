import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import SearchForm from '../components/SearchForm/SearchForm';
import Lists from '../components/Lists/Lists';
import { SearchContext } from '../contexts/SearchContext';
import { GlobalContext } from '../contexts/GlobalContext';
import { AbsoluteLink } from '../utils/LinkUtils';
import './Search.css';

import PageNotFound from './PageNotFound';
import ServerError from './ServerError';

function Search() {
    const { GlobalContextState } = useContext(GlobalContext);
    const { SearchContextState } = useContext(SearchContext);
    const { search_str=null, category_type='people', items=null, total=0, page=1, total_page=1, items_per_page=8, initial_load=true, page_not_found=false } = SearchContextState;
    // const { categoryStr='', pageNo=1, searchStr='' } = useParams();

    const helmet_title = search_str && search_str !== "" ? "Searched " + search_str : "Search Page";
    const helmet_descriprion = search_str && search_str !== "" ? "Searched for " + search_str + " and found " + total + " matches. Page " + page + " of " + total_page + "." : "Start searching by entering the name in the field to get results.";
    const helmet_link = search_str && search_str !== "" ? { path: "search/" + category_type + "/" + page + "/" + items_per_page + "/" + search_str } : { path: "/search" };
    
    if (page_not_found) {
        return ( <PageNotFound /> )
    } else if (page_not_found) {
        return ( <ServerError /> )
    } else {
        return (
            <div className={"page search-page"}>
                <Helmet>
                    <title>Project Alpha - {helmet_title}</title>
                    <meta name="description" content={helmet_descriprion} />
                    <link rel="canonical" href={ AbsoluteLink(helmet_link) }/>
                </Helmet>
                <h1>Search</h1>
                <div className={"search-wrapper"}>
                    <SearchForm />
                </div>
                {
                    search_str
                    ?
                        <div className={"search-lists-wrapper"}>
                            <Lists items={items} itemsPerPage={items_per_page} total={total} page={page} total_page={total_page} categoryStr={category_type} search_str={search_str} initial_load={initial_load} />
                        </div>
                    :
                        null
                }
            </div>
        )
    }
}

export default Search;