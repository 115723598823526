import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Pagination.css';

function Pagination(props) {
    const { data={}, path='PAGE_NO', showText=true, showTextHeader=true, showPagination=true } = props;
    const { search_str=null, total=0, page=1, total_page=1, items_per_page=10 } = data;
    const items_per_page_pagination = 7;
    const [ pageStart, setPageStart ] = useState((Math.floor( (page - 1) * 0.1) * items_per_page_pagination))
    const [ pageEnd, setPageEnd ] = useState((Math.ceil( page * 0.1) * items_per_page_pagination))
    const [ pageNo ] = useState((Math.ceil( total_page / items_per_page_pagination)))

    const goBack = () => {
        let newPageStart = pageStart - items_per_page_pagination;
        let newPageEnd = pageEnd - items_per_page_pagination;
        setPageStart(newPageStart)
        setPageEnd(newPageEnd)
    };

    const goForward = () => {
        let newPageStart = pageStart + items_per_page_pagination;
        let newPageEnd = pageEnd + items_per_page_pagination;
        setPageStart(newPageStart)
        setPageEnd(newPageEnd)
    };

    if (total !== 0) {
        return (
            <div className={'pagination'}>

                <hr />
                {
                    showTextHeader
                    ?
                        <div className={'pagination-header'}>
                            You Searched For "<b>{search_str.replaceAll('-',' ')}</b>". <b>{total}</b> Result{total === 1 ? '' : 's'} Found. 
                        </div>
                    :
                        null
                }
                {
                    showText
                    ?
                        <div className={'pagination-text'}>
                            Page(s) {pageEnd / items_per_page_pagination} of {pageNo}
                        </div>
                    :
                        null
                }
                {
                    showPagination
                    ?
                        <>
                            {
                                pageStart >= items_per_page_pagination
                                ?
                                    <button onClick={goBack} className={"pagination-item-link back"}>&laquo;</button>
                                :
                                    null
                            }
                            
                            {
                                (() => {
                                    let pagination_items = []; 
                                    for(let i = pageStart + 1; i <= pageEnd; i++) {
                                        if (i <= total_page) {
                                            pagination_items.push(<Link key={`pagination-item-link${i}`} to={path.replace('PAGE_NO', i)} className={"pagination-item-link" + (page === i ? " active" : "")}>{i}</Link>)
                                        }   
                                    }
                                    return pagination_items;
                                })()
                            }
                            {
                                total_page > pageEnd
                                ?
                                    <button onClick={goForward} className={"pagination-item-link forward"}>&raquo;</button>
                                :
                                    null
                            }
                        </>
                    :
                        null
                }
            </div>
            
        )
    } else {
        return null;
    }
}
  
export default Pagination;