import React, { createContext, useReducer, useEffect, useContext } from "react";
import { MagicCardReducer } from "./MagicCardReducer";
import { MagicCardState } from "./MagicCardState";

export const MagicCardContext = createContext();
const initialState = MagicCardState;

const MagicCardProvider = (props) => {
    console.log('MagicCardProvider');
    const [ MagicCardContextState, MagicCardContextDispatch ] = useReducer(MagicCardReducer, initialState);
    const { selected_suit, magic_card_suits, shuffle } = MagicCardContextState

    // const shuffleCard = (d1,d2,d3,d4) => {
    //     let shuffleCard = [];

    //     for (let i = 0; i <= 12 ; i++) {
    //         shuffleCard.push(d1[i]);
    //         shuffleCard.push(d2[i]);
    //         shuffleCard.push(d3[i]);
    //         shuffleCard.push(d4[i]);
    //     }
    //     return shuffleCard;
    // };

    const shuffleCard = () => {
        // console.log('---------shuffleCard----------')
        // console.log('before selected_suit', selected_suit)
        // console.log('before suits', magic_card_suits)
        let currentIndex = magic_card_suits.length;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {
            // console.log('currentIndex', currentIndex)
            // Pick a remaining element...
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [magic_card_suits[currentIndex], magic_card_suits[randomIndex]] = [magic_card_suits[randomIndex], magic_card_suits[currentIndex]];
        }

        // console.log('random suits', magic_card_suits);
        magic_card_suits.forEach(function(item,i){
            if(item === selected_suit){
                magic_card_suits.splice(i, 1);
                magic_card_suits.push(item);
            }
        });
        // console.log('sort suits', magic_card_suits);

        // console.log('Merging begin suits', magic_card_suits);
        let mergedCardSuits = [];
        magic_card_suits.forEach(function(item,i){
            mergedCardSuits.push(MagicCardContextState["magic_card_"+(i+1)+"_"+ magic_card_suits[i]]);
        });
        // console.log('-----mergedCardSuits', mergedCardSuits);

        let shuffledCards = []
        // console.log('shuffle cards being', shuffleCard);
        for (let i = 0; i <= 12 ; i++) {
            shuffledCards.push(mergedCardSuits[0][i]);
            shuffledCards.push(mergedCardSuits[1][i]);
            shuffledCards.push(mergedCardSuits[2][i]);
            shuffledCards.push(mergedCardSuits[3][i]);
        }
        // console.log('end cards being', shuffledCards);

        MagicCardContextDispatch({ type: "SET_UP_MAGIC_CARD", payload: { cards: shuffledCards } });

        // console.log('Merging begin suits', magic_card_suits);
        // let mergedCardSuits = [
        //     ...MagicCardContextState["magic_card_1_"+ magic_card_suits[0]],
        //     ...MagicCardContextState["magic_card_2_"+ magic_card_suits[1]],
        //     ...MagicCardContextState["magic_card_3_"+ magic_card_suits[2]],
        //     ...MagicCardContextState["magic_card_4_"+ magic_card_suits[3]]
        // ];
        // console.log('-----mergedCardSuits', mergedCardSuits);


        
    }

    useEffect(() => {
        //run set up magic_card_1_setup
        if (shuffle && selected_suit) {
            shuffleCard()
        }
       
    }, [shuffle, selected_suit])

    return (
        <MagicCardContext.Provider value={{MagicCardContextState, MagicCardContextDispatch}}>
            {props.children}
        </MagicCardContext.Provider>
    );
}

export default MagicCardProvider;