import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import './Videos.css';
import { GlobalContext } from "../contexts/GlobalContext";
import CameraComponent from "../components/CameraComponent/CameraComponent";

function CameraPage() {
    const { GlobalContextState } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null } = GlobalContextState;

    return (
        <>
            <Helmet>
                <title>Project Alpha - Camera</title>
                <meta name="description" content="Camera page descirption" />
                <link rel="canonical" href="http://test.com/" />
            </Helmet>
            <CameraComponent />
        </>
    )
}
  
export default CameraPage;