export const MagicCardState = {
    heart: [
        { type: 'heart', value: 1, faceValue: 'a' },
        { type: 'heart', value: 2, faceValue: '2' },
        { type: 'heart', value: 3, faceValue: '3' },
        { type: 'heart', value: 4, faceValue: '4' },
        { type: 'heart', value: 5, faceValue: '5' },
        { type: 'heart', value: 6, faceValue: '6' },
        { type: 'heart', value: 7, faceValue: '7' },
        { type: 'heart', value: 8, faceValue: '8' },
        { type: 'heart', value: 9, faceValue: '9' },
        { type: 'heart', value: 10, faceValue: '10' },
        { type: 'heart', value: 11, faceValue: 'j' },
        { type: 'heart', value: 12, faceValue: 'q' },
        { type: 'heart', value: 13, faceValue: 'k' },
    ],
    diamond: [
        { type: 'diamond', value: 1, faceValue: 'a' },
        { type: 'diamond', value: 2, faceValue: '2' },
        { type: 'diamond', value: 3, faceValue: '3' },
        { type: 'diamond', value: 4, faceValue: '4' },
        { type: 'diamond', value: 5, faceValue: '5' },
        { type: 'diamond', value: 6, faceValue: '6' },
        { type: 'diamond', value: 7, faceValue: '7' },
        { type: 'diamond', value: 8, faceValue: '8' },
        { type: 'diamond', value: 9, faceValue: '9' },
        { type: 'diamond', value: 10, faceValue: '10' },
        { type: 'diamond', value: 11, faceValue: 'j' },
        { type: 'diamond', value: 12, faceValue: 'q' },
        { type: 'diamond', value: 13, faceValue: 'k' },
    ],
    spade: [
        { type: 'spade', value: 1, faceValue: 'a' },
        { type: 'spade', value: 2, faceValue: '2' },
        { type: 'spade', value: 3, faceValue: '3' },
        { type: 'spade', value: 4, faceValue: '4' },
        { type: 'spade', value: 5, faceValue: '5' },
        { type: 'spade', value: 6, faceValue: '6' },
        { type: 'spade', value: 7, faceValue: '7' },
        { type: 'spade', value: 8, faceValue: '8' },
        { type: 'spade', value: 9, faceValue: '9' },
        { type: 'spade', value: 10, faceValue: '10' },
        { type: 'spade', value: 11, faceValue: 'j' },
        { type: 'spade', value: 12, faceValue: 'q' },
        { type: 'spade', value: 13, faceValue: 'k' },
    ],
    club: [
        { type: 'club', value: 1, faceValue: 'a' },
        { type: 'club', value: 2, faceValue: '2' },
        { type: 'club', value: 3, faceValue: '3' },
        { type: 'club', value: 4, faceValue: '4' },
        { type: 'club', value: 5, faceValue: '5' },
        { type: 'club', value: 6, faceValue: '6' },
        { type: 'club', value: 7, faceValue: '7' },
        { type: 'club', value: 8, faceValue: '8' },
        { type: 'club', value: 9, faceValue: '9' },
        { type: 'club', value: 10, faceValue: '10' },
        { type: 'club', value: 11, faceValue: 'j' },
        { type: 'club', value: 12, faceValue: 'q' },
        { type: 'club', value: 13, faceValue: 'k' },
    ],
    magic_card_1_heart: [
        { type: 'heart', value: 10, faceValue: '10' },
        { type: 'heart', value: 11, faceValue: 'j' },
        { type: 'heart', value: 12, faceValue: 'q' },
        { type: 'heart', value: 13, faceValue: 'k' },
        { type: 'heart', value: 1, faceValue: 'a' },
        { type: 'heart', value: 2, faceValue: '2' },
        { type: 'heart', value: 3, faceValue: '3' },
        { type: 'heart', value: 4, faceValue: '4' },
        { type: 'heart', value: 5, faceValue: '5' },
        { type: 'heart', value: 6, faceValue: '6' },
        { type: 'heart', value: 7, faceValue: '7' },
        { type: 'heart', value: 8, faceValue: '8' },
        { type: 'heart', value: 9, faceValue: '9' },
    ],
    magic_card_1_spade: [
        { type: 'spade', value: 10, faceValue: '10' },
        { type: 'spade', value: 11, faceValue: 'j' },
        { type: 'spade', value: 12, faceValue: 'q' },
        { type: 'spade', value: 13, faceValue: 'k' },
        { type: 'spade', value: 1, faceValue: 'a' },
        { type: 'spade', value: 2, faceValue: '2' },
        { type: 'spade', value: 3, faceValue: '3' },
        { type: 'spade', value: 4, faceValue: '4' },
        { type: 'spade', value: 5, faceValue: '5' },
        { type: 'spade', value: 6, faceValue: '6' },
        { type: 'spade', value: 7, faceValue: '7' },
        { type: 'spade', value: 8, faceValue: '8' },
        { type: 'spade', value: 9, faceValue: '9' },
    ],
    magic_card_1_diamond: [
        { type: 'diamond', value: 10, faceValue: '10' },
        { type: 'diamond', value: 11, faceValue: 'j' },
        { type: 'diamond', value: 12, faceValue: 'q' },
        { type: 'diamond', value: 13, faceValue: 'k' },
        { type: 'diamond', value: 1, faceValue: 'a' },
        { type: 'diamond', value: 2, faceValue: '2' },
        { type: 'diamond', value: 3, faceValue: '3' },
        { type: 'diamond', value: 4, faceValue: '4' },
        { type: 'diamond', value: 5, faceValue: '5' },
        { type: 'diamond', value: 6, faceValue: '6' },
        { type: 'diamond', value: 7, faceValue: '7' },
        { type: 'diamond', value: 8, faceValue: '8' },
        { type: 'diamond', value: 9, faceValue: '9' },
    ],
    magic_card_1_club: [
        { type: 'club', value: 10, faceValue: '10' },
        { type: 'club', value: 11, faceValue: 'j' },
        { type: 'club', value: 12, faceValue: 'q' },
        { type: 'club', value: 13, faceValue: 'k' },
        { type: 'club', value: 1, faceValue: 'a' },
        { type: 'club', value: 2, faceValue: '2' },
        { type: 'club', value: 3, faceValue: '3' },
        { type: 'club', value: 4, faceValue: '4' },
        { type: 'club', value: 5, faceValue: '5' },
        { type: 'club', value: 6, faceValue: '6' },
        { type: 'club', value: 7, faceValue: '7' },
        { type: 'club', value: 8, faceValue: '8' },
        { type: 'club', value: 9, faceValue: '9' },
    ],
    magic_card_2_heart: [    
        { type: 'heart', value: 7, faceValue: '7' },
        { type: 'heart', value: 8, faceValue: '8' },
        { type: 'heart', value: 9, faceValue: '9' },
        { type: 'heart', value: 10, faceValue: '10' },
        { type: 'heart', value: 11, faceValue: 'j' },
        { type: 'heart', value: 12, faceValue: 'q' },
        { type: 'heart', value: 13, faceValue: 'k' },    
        { type: 'heart', value: 1, faceValue: 'a' },
        { type: 'heart', value: 2, faceValue: '2' },
        { type: 'heart', value: 3, faceValue: '3' },
        { type: 'heart', value: 4, faceValue: '4' },
        { type: 'heart', value: 5, faceValue: '5' },
        { type: 'heart', value: 6, faceValue: '6' },    
    ],
    magic_card_2_spade: [    
        { type: 'spade', value: 7, faceValue: '7' },
        { type: 'spade', value: 8, faceValue: '8' },
        { type: 'spade', value: 9, faceValue: '9' },
        { type: 'spade', value: 10, faceValue: '10' },
        { type: 'spade', value: 11, faceValue: 'j' },
        { type: 'spade', value: 12, faceValue: 'q' },
        { type: 'spade', value: 13, faceValue: 'k' },    
        { type: 'spade', value: 1, faceValue: 'a' },
        { type: 'spade', value: 2, faceValue: '2' },
        { type: 'spade', value: 3, faceValue: '3' },
        { type: 'spade', value: 4, faceValue: '4' },
        { type: 'spade', value: 5, faceValue: '5' },
        { type: 'spade', value: 6, faceValue: '6' },    
    ],
    magic_card_2_diamond: [    
        { type: 'diamond', value: 7, faceValue: '7' },
        { type: 'diamond', value: 8, faceValue: '8' },
        { type: 'diamond', value: 9, faceValue: '9' },
        { type: 'diamond', value: 10, faceValue: '10' },
        { type: 'diamond', value: 11, faceValue: 'j' },
        { type: 'diamond', value: 12, faceValue: 'q' },
        { type: 'diamond', value: 13, faceValue: 'k' },    
        { type: 'diamond', value: 1, faceValue: 'a' },
        { type: 'diamond', value: 2, faceValue: '2' },
        { type: 'diamond', value: 3, faceValue: '3' },
        { type: 'diamond', value: 4, faceValue: '4' },
        { type: 'diamond', value: 5, faceValue: '5' },
        { type: 'diamond', value: 6, faceValue: '6' },    
    ],
    magic_card_2_club: [    
        { type: 'club', value: 7, faceValue: '7' },
        { type: 'club', value: 8, faceValue: '8' },
        { type: 'club', value: 9, faceValue: '9' },
        { type: 'club', value: 10, faceValue: '10' },
        { type: 'club', value: 11, faceValue: 'j' },
        { type: 'club', value: 12, faceValue: 'q' },
        { type: 'club', value: 13, faceValue: 'k' },    
        { type: 'club', value: 1, faceValue: 'a' },
        { type: 'club', value: 2, faceValue: '2' },
        { type: 'club', value: 3, faceValue: '3' },
        { type: 'club', value: 4, faceValue: '4' },
        { type: 'club', value: 5, faceValue: '5' },
        { type: 'club', value: 6, faceValue: '6' },    
    ],
    magic_card_3_heart: [
        { type: 'heart', value: 4, faceValue: '4' },
        { type: 'heart', value: 5, faceValue: '5' },
        { type: 'heart', value: 6, faceValue: '6' },
        { type: 'heart', value: 7, faceValue: '7' },
        { type: 'heart', value: 8, faceValue: '8' },
        { type: 'heart', value: 9, faceValue: '9' },
        { type: 'heart', value: 10, faceValue: '10' },
        { type: 'heart', value: 11, faceValue: 'j' },
        { type: 'heart', value: 12, faceValue: 'q' },
        { type: 'heart', value: 13, faceValue: 'k' },
        { type: 'heart', value: 1, faceValue: 'a' },
        { type: 'heart', value: 2, faceValue: '2' },
        { type: 'heart', value: 3, faceValue: '3' },
    ],
    magic_card_3_spade: [
        { type: 'spade', value: 4, faceValue: '4' },
        { type: 'spade', value: 5, faceValue: '5' },
        { type: 'spade', value: 6, faceValue: '6' },
        { type: 'spade', value: 7, faceValue: '7' },
        { type: 'spade', value: 8, faceValue: '8' },
        { type: 'spade', value: 9, faceValue: '9' },
        { type: 'spade', value: 10, faceValue: '10' },
        { type: 'spade', value: 11, faceValue: 'j' },
        { type: 'spade', value: 12, faceValue: 'q' },
        { type: 'spade', value: 13, faceValue: 'k' },
        { type: 'spade', value: 1, faceValue: 'a' },
        { type: 'spade', value: 2, faceValue: '2' },
        { type: 'spade', value: 3, faceValue: '3' },
    ],
    magic_card_3_diamond: [
        { type: 'diamond', value: 4, faceValue: '4' },
        { type: 'diamond', value: 5, faceValue: '5' },
        { type: 'diamond', value: 6, faceValue: '6' },
        { type: 'diamond', value: 7, faceValue: '7' },
        { type: 'diamond', value: 8, faceValue: '8' },
        { type: 'diamond', value: 9, faceValue: '9' },
        { type: 'diamond', value: 10, faceValue: '10' },
        { type: 'diamond', value: 11, faceValue: 'j' },
        { type: 'diamond', value: 12, faceValue: 'q' },
        { type: 'diamond', value: 13, faceValue: 'k' },
        { type: 'diamond', value: 1, faceValue: 'a' },
        { type: 'diamond', value: 2, faceValue: '2' },
        { type: 'diamond', value: 3, faceValue: '3' },
    ],
    magic_card_3_club: [
        { type: 'club', value: 4, faceValue: '4' },
        { type: 'club', value: 5, faceValue: '5' },
        { type: 'club', value: 6, faceValue: '6' },
        { type: 'club', value: 7, faceValue: '7' },
        { type: 'club', value: 8, faceValue: '8' },
        { type: 'club', value: 9, faceValue: '9' },
        { type: 'club', value: 10, faceValue: '10' },
        { type: 'club', value: 11, faceValue: 'j' },
        { type: 'club', value: 12, faceValue: 'q' },
        { type: 'club', value: 13, faceValue: 'k' },
        { type: 'club', value: 1, faceValue: 'a' },
        { type: 'club', value: 2, faceValue: '2' },
        { type: 'club', value: 3, faceValue: '3' },
    ],
    magic_card_4_heart: [
        { type: 'heart', value: 1, faceValue: 'a' },
        { type: 'heart', value: 2, faceValue: '2' },
        { type: 'heart', value: 3, faceValue: '3' },
        { type: 'heart', value: 4, faceValue: '4' },
        { type: 'heart', value: 5, faceValue: '5' },
        { type: 'heart', value: 6, faceValue: '6' },
        { type: 'heart', value: 7, faceValue: '7' },
        { type: 'heart', value: 8, faceValue: '8' },
        { type: 'heart', value: 9, faceValue: '9' },
        { type: 'heart', value: 10, faceValue: '10' },
        { type: 'heart', value: 11, faceValue: 'j' },
        { type: 'heart', value: 12, faceValue: 'q' },
        { type: 'heart', value: 13, faceValue: 'k' },
    ],
    magic_card_4_spade: [
        { type: 'spade', value: 1, faceValue: 'a' },
        { type: 'spade', value: 2, faceValue: '2' },
        { type: 'spade', value: 3, faceValue: '3' },
        { type: 'spade', value: 4, faceValue: '4' },
        { type: 'spade', value: 5, faceValue: '5' },
        { type: 'spade', value: 6, faceValue: '6' },
        { type: 'spade', value: 7, faceValue: '7' },
        { type: 'spade', value: 8, faceValue: '8' },
        { type: 'spade', value: 9, faceValue: '9' },
        { type: 'spade', value: 10, faceValue: '10' },
        { type: 'spade', value: 11, faceValue: 'j' },
        { type: 'spade', value: 12, faceValue: 'q' },
        { type: 'spade', value: 13, faceValue: 'k' },
    ],
    magic_card_4_diamond: [
        { type: 'diamond', value: 1, faceValue: 'a' },
        { type: 'diamond', value: 2, faceValue: '2' },
        { type: 'diamond', value: 3, faceValue: '3' },
        { type: 'diamond', value: 4, faceValue: '4' },
        { type: 'diamond', value: 5, faceValue: '5' },
        { type: 'diamond', value: 6, faceValue: '6' },
        { type: 'diamond', value: 7, faceValue: '7' },
        { type: 'diamond', value: 8, faceValue: '8' },
        { type: 'diamond', value: 9, faceValue: '9' },
        { type: 'diamond', value: 10, faceValue: '10' },
        { type: 'diamond', value: 11, faceValue: 'j' },
        { type: 'diamond', value: 12, faceValue: 'q' },
        { type: 'diamond', value: 13, faceValue: 'k' },
    ],
    magic_card_4_club: [
        { type: 'club', value: 1, faceValue: 'a' },
        { type: 'club', value: 2, faceValue: '2' },
        { type: 'club', value: 3, faceValue: '3' },
        { type: 'club', value: 4, faceValue: '4' },
        { type: 'club', value: 5, faceValue: '5' },
        { type: 'club', value: 6, faceValue: '6' },
        { type: 'club', value: 7, faceValue: '7' },
        { type: 'club', value: 8, faceValue: '8' },
        { type: 'club', value: 9, faceValue: '9' },
        { type: 'club', value: 10, faceValue: '10' },
        { type: 'club', value: 11, faceValue: 'j' },
        { type: 'club', value: 12, faceValue: 'q' },
        { type: 'club', value: 13, faceValue: 'k' },
    ],
    magic_card_suits: ['heart', 'spade', 'diamond', 'club'],
    magic_card_setup: null,
    shuffle: false,
    selected_suit: null,
}