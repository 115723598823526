import React, { useContext } from 'react';
import './ReactionCameraButton.css'; // Importing the CSS for this component
import { ReactionCameraContext } from '../../contexts/ReactionCameraContext';

const ReactionCameraButton = () => {

  const { ReactionCameraCotextDispatch } = useContext(ReactionCameraContext);

  const handleClick = (e) => { 
    console.log('handleClick', e, typeof e)   
    ReactionCameraCotextDispatch({ type: "SET_START_RECORDING" });
  } 


  return (
      <div className="reaction-camera-button" onClick={(e) => handleClick(e)}>
        <div className="camera-text">START</div> 
        <div className="camera-icon"></div>
        <div className="camera-text">REACTING</div>
      </div>
  );
};

export default ReactionCameraButton;