import React from 'react';
import './ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, title, content, cancel, confirm }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={"confirmation-modal"}>
      <div className={"content-wrapper"}>
        <h2>{title}</h2>
        <p>{content}</p>
        <button onClick={cancel}>
          Cancel
        </button>
        <button onClick={confirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
