import React, { useState, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { ValidateEmail, ValidatePassword } from '../../utils/ValidationUtils';
import { ForgotPasswordContext } from '../../contexts/ForgotPasswordContext';
import { RegistrationMessageConstant } from '../../constants/MessageConstants';
import './ForgotPasswordForm.css';

import VerificationModal from '../VerificationModal/VerificationModal'; // needed for verification
import { VerifyRequestForgotPassword, VerifyCode2 } from '../../services/VerificationServices'; // needed for verification

function ForgotPasswordForm(props) {
    const { enqueueSnackbar } = useSnackbar(); // needed for verification for feed back - optional 
    const { ForgotPasswordContextDispatch } = useContext(ForgotPasswordContext);
    const { path='/register', method='post' } = props;
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ emailAddressError, setEmailAddressError ] = useState(false);
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState(false);
    const [ passwordConfirmation, setPasswordConfirmation ] = useState('');
    const [ passwordConfirmationError, setPasswordConfirmationError ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ submit, setSubmit ] = useState(false);
    const [ submitEmail, setSubmitEmail ] = useState(false);
    const [ showVerification, setShowVerification ] = useState(false); // needed for verification
    const [ verifyId, setVerifyId ] = useState(''); // needed for verification
    const [ verifyCode, setVerifyCode ] = useState('');
    const [ showResetPasswordForm, setShowResetPasswordForm ] = useState(false);

    const handleOnSubmitEmail = (e) => {
        // console.log(e)
        try {
            e.preventDefault();
            //do validation
            setSubmitEmail(true);
            if (emailAddress.trim() === '' || !ValidateEmail(emailAddress)) {
                enqueueSnackbar(RegistrationMessageConstant.en.invalidEmailAddresError, { variant: 'error', autoHideDuration: 3000 });
                setEmailAddressError(true);
                setSubmitEmail(false);
            } else {
                VerifyRequestForgotPassword({ type: 'FORGOT_PASSWORD', email: emailAddress }).then(result => {
                    if (result.data.success === 1) {
                        setShowVerification(true);
                        setEmailAddressError(false);
                        setVerifyId(result.data.verification);
                    } else if (result.data.success === 0) {
                        enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }
                }).catch(err => {
                    console.log('VerificationRequest err', err)
                    if (err.response.data) {
                        if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                        }    
                    }
                });
            }
        } catch (err) {
            console.log('handleOnSubmit', err);
        }
    }

    const handleOnSubmit = (e) => {
        // console.log(e)
        try {
            e.preventDefault();
            //do validation
            setSubmit(true);
            if (password.trim() === '' || passwordConfirmation.trim() === '' || password.trim() !== passwordConfirmation.trim() || !ValidatePassword(password)) {
                if (password.trim() === '' || passwordConfirmation.trim() === '' || password.trim() !== passwordConfirmation.trim()) {
                    enqueueSnackbar(RegistrationMessageConstant.en.confirmationPasswordError, { variant: 'error', autoHideDuration: 3000 });
                    setPasswordError(true);
                    setPasswordConfirmationError(true);
                } else {
                    setPasswordError(false);
                    setPasswordConfirmationError(false);
                }

                if (!ValidatePassword(password)) {
                    enqueueSnackbar(RegistrationMessageConstant.en.invalidPasswordError, { variant: 'error', autoHideDuration: 3000 });
                    setPasswordError(true);
                } else {
                    setPasswordError(false);
                }
                setSubmit(false);
                console.log('FALSE');
                // return false;
            } else {
                console.log('TRUE');
                ForgotPasswordContextDispatch({ type: 'UPDATE_PASSWORD', payload: { email: emailAddress, password: password, id: verifyId, code: verifyCode } }) 
            }
        } catch (err) {
            console.log('handleOnSubmit', err);
        }
    }

    // needed for verification
    function VerifySubmit(code) {
        try {
            console.log('VerifiedSubmit', code);
            VerifyCode2({ id: verifyId, code: code.toUpperCase(), type: 'FORGOT_PASSWORD' }).then(result => {
                if (result.data.success === 1) {
                    setVerifyCode(code.toUpperCase());
                    setShowResetPasswordForm(true);
                    setShowVerification(false);
                } else if (result.data.success === 0) {
                    enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                }
            }).catch(err => {
                console.log('VerifiedSubmit err', err);
                if (err.response.data) {
                    if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }
                }
            });

        } catch (err) {
            console.log('VerifiedSubmit err', err);
        }
    }

    const handleOnShowPassword = (e) => {
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        setSubmit(false);
        if (password.trim() === passwordConfirmation.trim() && ValidatePassword(password)) {
            setPasswordError(false)
            setPasswordConfirmationError(false) 
        } else {
            setPasswordError(true)
            setPasswordConfirmationError(true) 
        }
        
    }, [passwordConfirmation, password])

    useEffect(() => {
        setSubmitEmail(false);
        if (emailAddress.trim() !== '') {
            if (ValidateEmail(emailAddress)) {
                setEmailAddressError(false);
            } else {
                setEmailAddressError(true);
            }
        } else {
            setEmailAddressError(false);
        }
    }, [emailAddress])

    return (
        <>
            <div className={'password-reset-form'}>
                {
                    showResetPasswordForm
                    ?
                        <form action={path} method={method} onSubmit={handleOnSubmit}>
                            <div className={'input-wrapper'}>
                            <input type={showPassword ? 'text' : 'password'} name={'password'} placeholder={'Password'} value={password} onChange={(e) => setPassword(e.target.value)} className={passwordError ? 'error' : ''} />
                            <label>{'Password'}</label>   
                            <span onClick={(e) => handleOnShowPassword(e)} class="material-symbols-rounded">{ showPassword ? 'visibility' : 'visibility_off' }</span>
                            <span className={"note"}>Password must contain 6-16 characters with a number and a special character</span>
                            </div>
                            <div className={'input-wrapper'}>
                                <input type={showPassword ? 'text' : 'password'} name={'password_confirmation'} placeholder={'Password Confirmation'} value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} className={passwordConfirmationError ? 'error' : ''} />
                                <label>{'Password Confirmation'}</label>   
                            </div>
                            {/* <div className={'input-wrapper'}>
                                <button type={'button'} onClick={(e) => handleOnShowPassword(e)}>{showPassword ? 'Hide Password' : 'Show Password'}</button>
                            </div> */}
                            <div className={'input-wrapper'}>
                                <input type={'submit'} value={'Submit'} disabled={submit || passwordConfirmationError || passwordError || password.length === 0 || passwordConfirmation.length === 0 ? 'disabled' : ''} />
                            </div>
                        </form>
                    :
                        <form action={path} method={method} onSubmit={handleOnSubmitEmail}>
                            <div className={'input-wrapper'}>
                                <input type={'text'} name={'email_address'} placeholder={'Email Address'} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} className={emailAddressError ? 'error' : ''} />
                                <label>{'Email Address'}</label>   
                            </div>
                            <div className={'input-wrapper'}>
                                <input type={'submit'} value={'Submit'} disabled={submitEmail || emailAddressError || emailAddress.length === 0 ? 'disabled' : ''} />
                            </div>
                        </form>
                }
            </div>
            <VerificationModal 
                isOpen={showVerification}
                title="Enter Verification Code"
                content="Code sent to your email." 
                cancel={setShowVerification} 
                confirm={VerifySubmit}
            />
        </>
    )
}
  
export default ForgotPasswordForm;