import axios from 'axios';
const loginApiVersion = process.env.REACT_APP_VERSION_API || '1.0';

export async function Login(objData) {
    let endpoint = `/api/${loginApiVersion}/user/login`;
    const data = await axios.post(endpoint, objData);
    return data;
}

export async function Logout(objData) {
    // console.log('Logout objData', objData)
    const { token = null } = objData;
    const data = await axios.post(
        `/api/${loginApiVersion}/user/logout`, 
        {},
        {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        }
    );
    return data;
}