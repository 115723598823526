import React, { useRef, useState, useEffect, useContext } from "react";
import { ReactionCameraContext } from '../../contexts/ReactionCameraContext';
import './ReactionCameraComponent.css';

let currentDeviceId = null;

function ReactionCameraComponent() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [streaming, setStreaming] = useState(false);
  const [faceMode, setFaceMode] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showCanvas, setShowCanvas] = useState(false);

  const { ReactionCameraContextState, ReactionCameraCotextDispatch } = useContext(ReactionCameraContext);
  const { isRecording, isPosting, isCameraOn, isFaceMode } = ReactionCameraContextState;

  async function getCameras() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      return devices.filter(device => device.kind === 'videoinput');
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  }
  
  async function switchCamera(currentDeviceId) {
    const cameras = await getCameras();
    const currentIndex = cameras.findIndex(device => device.deviceId === currentDeviceId);
    const nextCameraIndex = (currentIndex + 1) % cameras.length;
    const nextCamera = cameras[nextCameraIndex];
    return nextCamera.deviceId;
  }

  async function startVideoStream(cameraId) {
    const constraints = {
      video: { deviceId: { exact: cameraId } }
    };
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      document.querySelector('video').srcObject = stream;
    } catch (error) {
      console.error('Error starting video stream:', error);
    }
  }
  
  async function initCameraSwitch() {
    if (!currentDeviceId) {
      const cameras = await getCameras();
      currentDeviceId = cameras[0].deviceId; // Start with the first camera
    } else {
      currentDeviceId = await switchCamera(currentDeviceId);
    }
    startVideoStream(currentDeviceId);
  }

  function toggleFaceMode() {
    setFaceMode(!faceMode);
  }

  useEffect(async () => {
    try {
      initCameraSwitch()
      setStreaming(true);
    } catch (error) {
      console.error("Error opening video camera.", error);
    }
  }, []);

  useEffect(() => {
    console.log('Stop recording...(actually - pausing recording...');
    
    if (!isRecording && streaming) {
      // const stream = videoRef.current.srcObject;
      // videoRef.current.srcObject = stream;
      videoRef.current.pause();
    }
  }, [isRecording]);

  // useEffect(() => {
  //   toggleFaceMode();
  // }, [isFaceMode])

  useEffect(() => {
    if (!isFaceMode) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
  
      tracks.forEach(function (track) {
        track.stop();
        setStreaming(false);
        console.log('Stop streaming...Closing camera');
      });
    }
  }, [isFaceMode])

  // useEffect(() => {
  //   function handleResize() {
  //     if (streaming && videoRef.current && canvasRef.current) {
  //       const aspectRatio = videoRef.current.videoHeight / videoRef.current.videoWidth;
  //       setWidth(window.innerWidth);
  //       setHeight(window.innerWidth * aspectRatio);
  //       canvasRef.current.width = width;
  //       canvasRef.current.height = height;
  //     }
  //   }

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [streaming, width, height]);

  // const startVideo = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //     videoRef.current.srcObject = stream;
  //     videoRef.current.play();
  //   } catch (error) {
  //     console.error("Error opening video camera.", error);
  //   }
  // };

  // const stopVideo = () => {
  //   const stream = videoRef.current.srcObject;
  //   const tracks = stream.getTracks();

  //   tracks.forEach(function (track) {
  //     track.stop();
  //     setStreaming(false);
  //     console.log('Stop streaming...');
  //   });
  // };

  // const captureImage = () => {
  //   if (canvasRef.current && videoRef.current) {        
  //     // videoRef.current.width = width;
  //     // videoRef.current.height = height;
  //     console.log('videoRef.current.width', videoRef.current.width);
  //     console.log('videoRef.current.height', videoRef.current.height)
  //     canvasRef.current.width = 106;
  //     canvasRef.current.height = 42;
  //     const ctx = canvasRef.current.getContext("2d");
  //     ctx.drawImage(videoRef.current, 0, 0, width, height);
  //     setShowCanvas(true);
  //     stopVideo();
  //   }
  // };

  // const handleCanPlay = () => {
  //   if (!streaming) {
  //     const aspectRatio = videoRef.current.videoHeight / videoRef.current.videoWidth;
  //     setWidth(window.innerWidth);
  //     setHeight(window.innerWidth * aspectRatio);

  //     if (videoRef.current && canvasRef.current) {
  //       videoRef.current.width = width;
  //       videoRef.current.height = height;
  //       canvasRef.current.width = width;
  //       canvasRef.current.height = height;

  //       setStreaming(true);
  //     }
  //   }
  // };

  return (
    <div className={"camera-wrapper"}>
      <video ref={videoRef} className={"camera-video" + (faceMode ? "facing-mode" : "")} playsInline></video>
      {/* <canvas ref={canvasRef} className={"canvas-video facing-mode"} /> */}
      
      {/* <canvas 
        ref={canvasRef} 
        style={{ 
          maxWidth: '100%', 
          display: showCanvas ? 'block' : 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 3
        }} 
      /> */}
      {/* <div style={{ position: 'fixed', bottom: '10px', left: '50%', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'space-between', width: '90%' }}>
        <button onClick={startVideo}>Start Video</button>
        <button onClick={stopVideo}>Stop Video</button>
        <button onClick={captureImage}>Capture</button>
      </div> */}
    </div>
  );
}

export default ReactionCameraComponent;
