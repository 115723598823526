import axios from 'axios';
const verificationApiVersion = process.env.REACT_APP_VERSION_API || '1.0';

export async function VerifyRequest(objData) {
    console.log('VerifyRequest');
    const data = await axios.post(
        `/api/${verificationApiVersion}/user/request`,
        objData
    );
    return data;
}

export async function VerifyRequestRegistration(objData) {
    console.log('VerifyRequestRegistration');
    const data = await axios.post(
        `/api/${verificationApiVersion}/user/request/registration`,
        objData
    );
    return data;
}

export async function VerifyRequestForgotPassword(objData) {
    console.log('VerifyRequestForgotPassword');
    const data = await axios.post(
        `/api/${verificationApiVersion}/user/request/forgot-password`,
        objData
    );
    return data;
}

export async function VerifyCode(objData) {
    console.log('VerifyCode');
    const data = await axios.post(
        `/api/${verificationApiVersion}/user/verify`,
        objData
    );
    return data;
}

export async function VerifyCode2(objData) {
    console.log('VerifyCode2');
    const data = await axios.post(
        `/api/${verificationApiVersion}/user/verify/2`,
        objData
    );
    return data;
}