import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import './Videos.css';
import { GlobalContext } from "../contexts/GlobalContext";
import ReactionCamera from "../components/ReactionCamera.2/ReactionCamera.2";

function ReactionCameraPage() {
    const { GlobalContextState } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null } = GlobalContextState;

    return (
        <>
            <Helmet>
                <title>Project Alpha - Reaction Camera</title>
                <meta name="description" content="Reaction Camera" />
                <link rel="canonical" href="http://test.com/" />
            </Helmet>
            <ReactionCamera />
        </>
    )
}
  
export default ReactionCameraPage;