import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "./GlobalContext";
import { ForgotPasswordReducer } from "./ForgotPasswordReducer";
import { ForgotPasswordState } from "./ForgotPasswordState";
import { ForgotPassword } from "../services/ForgotPasswordServices";
import { useSnackbar } from 'notistack';

export const ForgotPasswordContext = createContext();
const initialState = ForgotPasswordState;

const ForgotPasswordContextProvider = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { GlobalContextDispatch } = useContext(GlobalContext);
    const [ ForgotPasswordContextState, ForgotPasswordContextDispatch] = useReducer(ForgotPasswordReducer, initialState);
    const { email=null, password=null, id=null, code=null } = ForgotPasswordContextState;

    useEffect(() => {
        if (email && password && id && code) {
            ForgotPassword({ email: email, password: password, id: id, code: code }).then(result => {
                if (result.data.success === 1) {
                    GlobalContextDispatch({ type: "LOGOUT_SESSION", payload: result.data });
                    navigate(`/login`);
                } else if (result.data.success === 0 && result.data.message && result.data.message !== '') { 
                    enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                }    
                ForgotPasswordContextDispatch({ type: 'RESET_FIELDS' })
            }).catch(err => {
                if (err.response.data) {
                    if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }    
                }
                ForgotPasswordContextDispatch({ type: 'RESET_FIELDS' })
            });
        }
    }, [email, password])

    return (
        <ForgotPasswordContext.Provider value={{ForgotPasswordContextState, ForgotPasswordContextDispatch}}>
            {props.children}
        </ForgotPasswordContext.Provider>
    );
}

export default ForgotPasswordContextProvider;