import React from 'react';
import './FullScreenVideo.css'; // Importing the CSS for this component
import VideoThumbnailList from '../VideoThumbnailList/VideoThumbnailList';

const FullScreenVideo = ({ src }) => {

// const videoList = [
//   "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1671088454643.mp4",
//   "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1668877254295.mp4",
//   "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646434163316.mp4",
//   // add more video URLs here
// ];

  const videoList = [
    "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_80,w_60/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1661229160013.mp4",
    "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_80,w_60/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1634357689314.mp4",
    "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_80,w_60/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646470986690.mp4",
    "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_80,w_60/du_10/v1/videos/users/60d42e7b7ccf630004446fc3/video-1644299104619.mp4",
    "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_80,w_60/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/trim.7C14BEF1-07E9-47A7-8386-3D552879860F%20(3).mp4",
    "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_80,w_60/du_10/v1/videos/users/60d95f5be66073000429d221/video-1637085302741.mp4"
  ]
  return (
    <div className="videoContainer">
      <VideoThumbnailList videoList={videoList} />
      <video className="responsiveVideo" src={src} autoPlay loop muted />
      {/* <video className="responsiveVideo" src={src} /> */}
    </div>
  );
};

export default FullScreenVideo;