import React from 'react';
import './VideoThumbnailList.css'; // Importing the CSS for this component

const VideoThumbnailList = ({ videoList }) => {
  return (
    <div className="thumbnailListContainer">
      {videoList.map((videoSrc, index) => (
        <div className="videoThumbnail" key={index}>
          <video src={videoSrc} autoPlay loop muted />
        </div>
      ))}
    </div>
  );
};

export default VideoThumbnailList;