export function ItemRelativeLink(obj) {  
    const { item_id=null, item_name=null } = obj;
    let item_link = null;
    if (item_id && item_name) {
        item_link = `/item/${item_id}/${item_name.trim().replaceAll(" ","-")}`;
    }
    return item_link;
}

export function ItemAbsoluteLink(obj) {  
    const { item_id=null, item_name=null } = obj;
    let item_link = null;
    if (item_id && item_name) {
        item_link = `${process.env.REACT_APP_LOCATION_ORIGIN}/item/${item_id}/${item_name.trim().replaceAll(" ","-")}`;
    }
    return item_link;
}

export function AbsoluteLink(obj) {  
    const { path=null } = obj;
    let item_link = null;
    if (path) {
        item_link = `${process.env.REACT_APP_LOCATION_ORIGIN}/${path.trim()}`;
    }
    return item_link;
}

