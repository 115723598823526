export const ItemReducer = (state, action) => {
    switch(action.type){
        case 'SET_ITEM': 
            return {
                ...state,
                item: action.payload.item,   
                initial_load: false
            }
        case 'PAGE_NOT_FOUND':
            return {
                ...state,
                page_not_found: true
            }
        case 'SERVER_ERROR':
            return {
                ...state,
                server_error: true
            }    
        default:
            return state    
    }
}