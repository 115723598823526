import React from 'react';
// import ReactionBubbleListItem from '../ReactionVideoItem/ReactionBubbleListItem';
import './ReactionBubbleList.css'; // Importing the CSS for this component

const ReactionBubbleList = ({ videoList }) => {
  return (
    <>
      <div className="item-bubbles">

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-45">
            <img
              alt="image bubble"
              src="https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_100,w_100/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646434163316.jpg"
              className="bubble-image"
            />
            <div className="item-bubble-live">LIVE</div>
          </div>
        </div>

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-60">
            <img
              alt="image bubble"
              src="https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_100,w_100/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646470986690.jpg"
              className="bubble-image"
            />
            <div className="item-bubble-live top">LIVE</div>
          </div>
          <div className="item-bubble item-bubble-50">
            <img
              alt="image bubble"
              src="https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_100,w_100/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1661229160013.jpg"
              className="bubble-image"
            />
            <div className="item-bubble-live">LIVE</div>
          </div>
        </div>

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-30">
            <video
              loop 
              muted
              autoPlay
              playsInline 
              className="bubble-video"     
              preload="auto"
              src="https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_100,w_100/du_10/v1/videos/users/60d42e7b7ccf630004446fc3/video-1644299104619.mp4"
              type="video/mp4"></video>
            <div className="item-bubble-live">LIVE</div>
          </div>
        </div>

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-100">
            <video
              loop 
              muted
              autoPlay
              playsInline 
              className="bubble-video"     
              preload="auto"
              src="https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_80,w_60/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/trim.7C14BEF1-07E9-47A7-8386-3D552879860F%20(3).mp4"
              type="video/mp4"></video>
            <div className="item-bubble-live">LIVE</div>
          </div>
        </div>

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-30">
            <img
              alt="image bubble"
              src="https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_100,w_100/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1668877254295.jpg"
              className="bubble-image"
            />
            <div className="item-bubble-live">LIVE</div>
          </div>
        </div>

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-50">
            <img
              alt="image bubble"
              src="https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_100,w_100/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646470986690.jpg"
              className="bubble-image"
            />
            <div className="item-bubble-live top">LIVE</div>
          </div>
          <div className="item-bubble item-bubble-60">
            <img
              alt="image bubble"
              src="https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_100,w_100/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1661229160013.jpg"
              className="bubble-image"
            />
            <div className="item-bubble-live">LIVE</div>
          </div>
        </div>

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-45">
            9
          </div>
        </div>

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-60">
            10
          </div>
          <div className="item-bubble item-bubble-50">
            11
          </div>
        </div>

        <div className="item-bubble-box">
          <div className="item-bubble item-bubble-30">
            12
          </div>
        </div>

      </div>
    </>
  );
};

export default ReactionBubbleList;