import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import './Navigation.css';
import { GlobalContext } from "../../contexts/GlobalContext";
import { Logout } from "../../services/LoginServices";

function Navigation() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null, show_navigation = false } = GlobalContextState;
    const [ showNavigation, setShowNavigation ] = useState(false || show_navigation);
    const [ showSubNavigation, setShowSubNavigation ] = useState(false);

    const handleClickSbowSubNavigation = (e) => {
        setShowSubNavigation(!showSubNavigation)
    };

    const handleClickLink = (e) => {
        setShowSubNavigation(!showSubNavigation)
    };

    const handleClickLogout = (e) => {
        e.preventDefault();
        setShowSubNavigation(!showSubNavigation);
        Logout({ token: global_session_key }).then(result => {
            // console.log('Logout', result.data)
            if (result.data.success === 1) {
                // console.log('navigate');
                GlobalContextDispatch({ type: 'LOGOUT_SESSION' })
                navigate('/login');
            } else if (result.data.success === 0 && result.data.message && result.data.message !== '') { 
                enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
            }    
        }).catch(err => {
            if (err.response.data) {
                if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                    enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                }    
            }
        });
    }
    
    useEffect(() => {
        if (showNavigation !== show_navigation) {
            setShowNavigation(show_navigation)
        }
    }, [show_navigation])

    return (
        <>
            {
                showNavigation
                ?
                    
                    <div className={'navigation'}>
                        <div className={'header'}>
                            <img src={"/logo512.png"} className="header-logo" alt="logo" width={"40"} height={"40"} />
                            <button type={'button'} className={"header-menu " + (showSubNavigation ? " open" : "")} onClick={(e) => handleClickSbowSubNavigation(e)}>
                            {
                                showSubNavigation 
                                ? 
                                    <span class="material-symbols-rounded">
                                        menu
                                    </span> 
                                : 
                                    <span class="material-symbols-rounded">
                                        menu
                                    </span>
                            }
                            </button>
                        </div>
                        <div className={'sub-navigation ' + (showSubNavigation ? 'show' : '') }>
                            <nav>
                                <ul>
                                    <li><Link to='/' onClick={(e) => handleClickLink(e)}>Home</Link></li>
                                    <li><Link to='/search' onClick={(e) => handleClickLink(e)}>Search</Link></li>
                                    <li><Link to='/about-us' onClick={(e) => handleClickLink(e)}>About Us</Link></li>
                                    <li><Link to='/faqs' onClick={(e) => handleClickLink(e)}>FAQs</Link></li>
                                    <li><Link to='/terms' onClick={(e) => handleClickLink(e)}>Terms</Link></li>
                                    <li><Link to='/privacy' onClick={(e) => handleClickLink(e)}>Privacy</Link></li>
                                    {
                                        !global_session && global_session_key === null && account === null
                                        ?
                                            <>
                                                <li className={"divider"}><Link to='/register' onClick={(e) => handleClickLink(e)}>Register</Link></li>
                                                <li><Link to='/login' onClick={(e) => handleClickLink(e)}>Login</Link></li>
                                                <li><Link to='/forgot-password' onClick={(e) => handleClickLink(e)}>Forgot Password</Link></li>
                                            </>
                                        :
                                            <>
                                                <li className={"divider"}><Link to='/videos' onClick={(e) => handleClickLink(e)}>Videos</Link></li>
                                                <li><Link to='/camera' onClick={(e) => handleClickLink(e)}>Camera</Link></li>
                                                <li><Link to='/account' onClick={(e) => handleClickLink(e)}>Account</Link></li>
                                                <li><Link to='/logout' onClick={(e) => handleClickLogout(e)}>Logout</Link></li>
                                            </>
                                    }
                                </ul>
                            </nav>
                        </div>
                    </div>
                :
                    null
            }
        </>
    )
}
  
export default Navigation;