import React from 'react';
import { useNavigate } from "react-router-dom";
import './BackButton.css';

function BackButton(props) {
    const navigate = useNavigate();
    const { classes='' } = props;
    const goBack = () => {
        navigate(-1);
    };
    return (
        <button onClick={goBack} className={'back-button ' + classes}>
            &larr; Go Back
        </button>
    )
}
  
export default BackButton;