import React, { useState,useContext, useEffect } from 'react';
import { MagicCardContext } from '../../contexts/MagicCardContext';
import CardDeck from './CardDeck';
import Card from './Card.1';
import './MagicCard.1.css';

// const questions = [
//     "A standard deck of playing cards contains four suits: hearts, diamonds, clubs, and spades. Which one of these suits would you like to choose?",
//     "In a standard deck of playing cards, the face values are assigned numerically from Ace as 1, through the numbered cards from two to ten, and continuing with the face cards where Jack is 11, Queen is 12, and King is 13. Now, choose a number from 1-13.",
//     "Now, double the chosen number.",
//     "Now, double it again and remeber the total",
//     "Now, I would like you to flip the card until you reach that total number, that total number will be the card you have chosen"
// ];

const questions = [
    "A deck of playing cards is a treasure chest of four fabulous suits: hearts, diamonds, clubs, and spades. Which dazzling suit will you pick today?",
    "In the realm of playing cards, we count from the cunning Ace at 1, through the spirited numbers up to ten, ascending to the noble court where Jack is 11, Queen is 12, and King stands tall at 13. Which number will be your champion from 1 to 13?",
    "Time for a twist! Take your chosen number and magically double it.",
    "Let's ramp up the magic! Double your number once more and remember this grand total—it holds the key to your next move.",
    "Now, let's bring your card into play! Flip through the deck card by card until you reach your grand total. The card you land on is uniquely yours—what does it reveal about your journey?"
]


const MagicCard = () => {

    const { MagicCardContextState, MagicCardContextDispatch } = useContext(MagicCardContext);
    const { magic_card_setup } = MagicCardContextState;
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const [flipCount, setFlipCount] = useState(0);
        
    const setSuit = (suit) => {
        MagicCardContextDispatch({ type: "SET_UP_SHUFFLE", payload: { shuffle: true, selected_suit: suit } });
        nextQuestion();
    }

    const resetSuit = () => {
        MagicCardContextDispatch({ type: "RESET_UP_SHUFFLE" });
        setCurrentQuestionIndex(0);
        setFlipCount(0);
    }

    const nextQuestion = () => {
        setOpacity(0);
        setTimeout(() => {
        setCurrentQuestionIndex((prevIndex) =>
            prevIndex < questions.length - 1 ? prevIndex + 1 : 0
        );
        setOpacity(1);
        }, 500); // Corresponds to the transition time
    };

    const flipCard = () => {
        if (flipCount < 52) {
            setFlipCount((prevCount) => prevCount + 1);
        }
    };

    useEffect(() => {
        // if (magic_card_setup) {
        //     console.log('magic_card_setup', magic_card_setup)
        // }

    }, [magic_card_setup])

    return (
        <div className="question-container">
            <div className="question-card" style={{ opacity }}>

                <h2>{questions[currentQuestionIndex]}</h2>

                {  
                    currentQuestionIndex === 0
                    ?
                        <div className={"box-action"}> 
                            <div onClick={() => setSuit("heart")} className={"card heart heart-a"}></div>
                            <div onClick={() => setSuit("spade")} className={"card spade spade-a"}></div>
                            <div onClick={() => setSuit("diamond")} className={"card diamond diamond-a"}></div>
                            <div onClick={() => setSuit("club")} className={"card club club-a"}></div>
                        </div>
                    :
                        currentQuestionIndex === (questions.length-1)
                        ?
                            <>
                                {/* <button onClick={flipCard}>Flip</button> */}
                                <div className={"box box-left"}>
                                    <CardDeck color={"blue"} action={flipCard} />
                                    <div>TAP TO FLIP</div>
                                </div>
                                {
                                    flipCount !== 0
                                    ?
                                        <div className={"box box-right"}>
                                            <Card type={magic_card_setup[flipCount-1].type} value={magic_card_setup[flipCount-1].faceValue} />
                                            <div className={"counter"}>{flipCount}</div>
                                        </div>
                                    :
                                        null
                                }

                                {
                                    flipCount === 52
                                    ?
                                        <div onClick={resetSuit} className={"card joker"}>AGAIN?</div>
                                    :
                                        null
                                }
                                
                            </>
                        :
                            <div onClick={nextQuestion} className={"card joker"}>NEXT</div>
                }

            </div>
        </div>
    );
};

export default MagicCard;
