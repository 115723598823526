import React, { useContext } from 'react';
import './ReactionCameraView.css'; // Importing the CSS for this component
import { ReactionCameraContext } from '../../contexts/ReactionCameraContext';
import ReactionCameraComponent from '../ReactionCameraComponent/ReactionCameraComponent';

const ReactionCameraView = ({ videoList }) => {

  const { ReactionCameraContextState, ReactionCameraCotextDispatch } = useContext(ReactionCameraContext);
  const { isRecording, isPosting, isCameraOn } = ReactionCameraContextState;

  return (
      <div className="reaction-camera-view">
        {
          isCameraOn
          ?
            <>
              {
                isRecording
                ?
                  <div className="recording-wrapper-icon">
                    <div className="recording-icon">
                      
                    </div>  
                    <div className="recording-text">
                      REC
                    </div>
                  </div> 
                :
                  null
              }
              <ReactionCameraComponent />
            </>
           :
              isPosting
              ?
                <>Posting...</>
              :
                null
        }
      </div>
  );
};

export default ReactionCameraView;