import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterReducer } from "./RegisterReducer";
import { RegisterState } from "./RegisterState";
import { Register } from "../services/RegisterServices";
import { GlobalContext } from "./GlobalContext";
import { useSnackbar } from 'notistack';

export const RegisterContext = createContext();
const initialState = RegisterState;

const RegisterContextProvider = (props) => {
    const navigate = useNavigate();
    const { redirect = '/account' } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { GlobalContextDispatch } = useContext(GlobalContext);
    const [RegisterContextState, RegisterContextDispatch] = useReducer(RegisterReducer, initialState);
    const { email=null, password=null } = RegisterContextState;

    useEffect(() => {
        if (email && password) {
            // RegisterContextDispatch({ type: 'SET_PROCESS', payload: { is_processing: true } })
            Register({ email: email, password: password }).then(result => {
                if (result.data.success === 1) {
                    GlobalContextDispatch({ type: "SET_SESSION", payload: result.data });
                    navigate(redirect);
                } else if (result.data.success === 0 && result.data.message && result.data.message !== '') { 
                    enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                }    
                RegisterContextDispatch({ type: 'RESET_EMAIL_PASSORD' })
            }).catch(err => {
                if (err.response.data) {
                    if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }    
                }
                RegisterContextDispatch({ type: 'RESET_EMAIL_PASSORD' })
            });
        }
    }, [email, password])

    return (
        <RegisterContext.Provider value={{RegisterContextState, RegisterContextDispatch}}>
            {props.children}
        </RegisterContext.Provider>
    );
}

export default RegisterContextProvider;