import React from 'react';
import { Helmet } from "react-helmet";
import { AbsoluteLink } from '../utils/LinkUtils';
import './Privacy.css';

function Privacy() {
    return (
        <div className={"page privacy-page"}>
            <Helmet>
                <title>Project Alpha - Privacy Policy</title>
                <meta name="description" content="Privacy Policy descirption" />
                <link rel="canonical" href={AbsoluteLink({ path: '/faqs' })} />
            </Helmet>
            <h1>Privacy Policy</h1>
            <div className={"privacy-wrapper"}>
            <h2>Information Collection</h2>
                <p>We may collect personal identification information from Users in various ways, including but not limited to when Users visit our Site, register on the Site, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for their name, email address, and other relevant information. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site-related activities.</p>

                <h2>Information Usage</h2>
                <p>We may collect and use Users' personal information for the following purposes:</p>
                <ul>
                    <li>To personalize the user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
                    <li>To improve our Site: We continually strive to improve our website offerings based on the information and feedback we receive from Users.</li>
                    <li>To send periodic emails: We may use the email address to respond to inquiries, questions, and/or other requests from Users. If a User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</li>
                </ul>

                <h2>Information Protection</h2>
                <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of Users' personal information and data stored on our Site.</p>

                <h2>Sharing Personal Information</h2>
                <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>

                <h2>Disclaimer</h2>
                <p>The information provided on this blog site is for general informational purposes only. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the blog or the information, products, services, or related graphics contained on the blog for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>

                <p>In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this blog.</p>
            </div>
        </div>
    )
}

export default Privacy;