import React from 'react';
import { Helmet } from "react-helmet";
import { AbsoluteLink } from '../utils/LinkUtils';
import './PageNotFound.css';

function PageNotFound() {
    return (
        <div className="page page-not-found">
            <Helmet>
                <title>Project Alpha - Page Not Found</title>
                <meta name="description" content="Page not found, please try again" />
                <link rel="canonical" href={AbsoluteLink({ path: '/404' })} />
            </Helmet>
            <h1>Page Not Found</h1>
            <div className={"page-not-found-wrapper"}>
               
            </div>
        </div>
    )
}

export default PageNotFound;