import React from 'react';
import ReactDOMClient, { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'

import { SnackbarProvider } from 'notistack';
import '../node_modules/normalize.css';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


console.log('window.__is_ssr__',window.__is_ssr__);
if (window.__is_ssr__ === true) {
  console.log('1');
  ReactDOMClient.hydrateRoot(document.getElementById('root'), 
    <BrowserRouter>
      <SnackbarProvider
        preventDuplicate  
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        maxSnack={3}
        classes={{
          variantInfo: {
            backgroundColor: 'rgba(253,45,85,1)'
          }
        }}
      >
        <App context={window.__initial_data__} />
      </SnackbarProvider>
    </BrowserRouter>
  );
} else {
  console.log('2');
  const domNode = document.getElementById('root');
  const root = createRoot(domNode);
  root.render(
    <BrowserRouter>
      <SnackbarProvider
        preventDuplicate  
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        maxSnack={3}
        classes={{
          variantInfo: {
            backgroundColor: 'rgba(253,45,85,1)'
          }
        }}
      >
        <App context={window.__initial_data__} />
      </SnackbarProvider>
    </BrowserRouter>
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();