import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { ValidateEmail, ValidatePassword } from '../../utils/ValidationUtils';
import { RegisterContext  } from '../../contexts/RegisterContext';
import { RegistrationMessageConstant } from '../../constants/MessageConstants';
import './RegistrationForm.css';

import VerificationModal from '../../components/VerificationModal/VerificationModal'; // needed for verification
import { VerifyRequestRegistration, VerifyCode } from '../../services/VerificationServices'; // needed for verification

function RegistrationForm(props) {
    const { enqueueSnackbar } = useSnackbar(); // needed for verification for feed back - optional 
    const { RegisterContextDispatch } = useContext(RegisterContext);
    const { path='/register', method='post' } = props;
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ emailAddressError, setEmailAddressError ] = useState(false);
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState(false);
    const [ passwordConfirmation, setPasswordConfirmation ] = useState('');
    const [ passwordConfirmationError, setPasswordConfirmationError ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ submit, setSubmit ] = useState(false);
    const [ showVerification, setShowVerification ] = useState(false); // needed for verification
    const [ verifyId, setVerifyId ] = useState(''); // needed for verification

    const handleOnSubmit = (e) => {
        // console.log(e)
        try {
            e.preventDefault();
            //do validation
            setSubmit(true);
            if (emailAddress.trim() === '' || !ValidateEmail(emailAddress) || password.trim() === '' || passwordConfirmation.trim() === '' || password.trim() !== passwordConfirmation.trim() || !ValidatePassword(password)) {
                if (emailAddress.trim() === '' || !ValidateEmail(emailAddress)) {
                    enqueueSnackbar(RegistrationMessageConstant.en.invalidEmailAddresError, { variant: 'error', autoHideDuration: 3000 });
                    setEmailAddressError(true);
                } else {
                    setEmailAddressError(false);
                }
    
                if (password.trim() === '' || passwordConfirmation.trim() === '' || password.trim() !== passwordConfirmation.trim()) {
                    enqueueSnackbar(RegistrationMessageConstant.en.confirmationPasswordError, { variant: 'error', autoHideDuration: 3000 });
                    setPasswordError(true);
                    setPasswordConfirmationError(true);
                } else {
                    setPasswordError(false);
                    setPasswordConfirmationError(false);
                }

                if (!ValidatePassword(password)) {
                    enqueueSnackbar(RegistrationMessageConstant.en.invalidPasswordError, { variant: 'error', autoHideDuration: 3000 });
                    setPasswordError(true);
                } else {
                    setPasswordError(false);
                }
                setSubmit(false);
                console.log('FALSE');
                // return false;
            } else {
                console.log('TRUE');
                VerifyRequestRegistration({ type: 'USER_REGISTRATION', email: emailAddress }).then(result => {
                    if (result.data.success === 1) {
                        setShowVerification(true);
                        setVerifyId(result.data.verification);
                    } else if (result.data.success === 0) {
                        enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }
                }).catch(err => {
                    console.log('VerificationRequest err', err)
                    if (err.response.data) {
                        if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                        }    
                    }
                });
            }
        } catch (err) {
            console.log('handleOnSubmit', err);
        }
    }

    // needed for verification
    const VerifiedSubmit = (code) => {
        try {
            console.log('VerifiedSubmit' , code);
            VerifyCode({ id: verifyId, code: code.toUpperCase(), type: 'USER_REGISTRATION' }).then(result => {
                if (result.data.success === 1) {
                    setPasswordError(false);
                    setPasswordConfirmationError(false);
                    setEmailAddressError(false);
                    // setSubmit(false);
                    RegisterContextDispatch({ type: 'SET_REGISTER', payload: { email: emailAddress, password: password } }) 
                } else if (result.data.success === 0) {
                    enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                }
            }).catch(err => {
                console.log('VerificationRequest err', err)
                if (err.response.data) {
                    if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }    
                }
            });
            
        } catch (err) {
            console.log('VerifiedSubmit err', err)
        }
    }

    const handleOnShowPassword = (e) => {
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        setSubmit(false);
        if (password.trim() !== '' || passwordConfirmation.trim() !== '') {
            if (password.trim() === passwordConfirmation.trim() && ValidatePassword(password)) {
                setPasswordError(false)
                setPasswordConfirmationError(false) 
            } else {
                setPasswordError(true)
                setPasswordConfirmationError(true) 
            
            }
        } else {
            setPasswordError(false)
            setPasswordConfirmationError(false) 
        }
    }, [passwordConfirmation, password])

    useEffect(() => {
        setSubmit(false);
        if (emailAddress.trim() !== '') {
            if (ValidateEmail(emailAddress)) {
                setEmailAddressError(false);
            } else {
                setEmailAddressError(true);
            }
        } else {
            setEmailAddressError(false);
        }
    }, [emailAddress])

    // useEffect(() => {
    //     if (!is_processing) {
    //         setSubmit(false);
    //     }
    // }, [is_processing])

    return (
        <>
            <div className={'register-form'}>
                <form action={path} method={method} onSubmit={handleOnSubmit}>
                    <div className={'input-wrapper'}>
                        <input type={'text'} name={'email_address'} placeholder={'Email Address'} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} className={emailAddressError ? 'error' : ''} />
                        <label>{'Email Address'}</label>   
                    </div>
                    <div className={'input-wrapper'}>
                        <input type={showPassword ? 'text' : 'password'} name={'password'} placeholder={'Password'} value={password} onChange={(e) => setPassword(e.target.value)} className={passwordError ? 'error' : ''} />
                        <label>{'Password'}</label>  
                        <span onClick={(e) => handleOnShowPassword(e)} class="material-symbols-rounded">{ showPassword ? 'visibility' : 'visibility_off' }</span>
                        <span className={"note"}>Password must contain 6-16 characters with a number and a special character</span>
                    </div>
                    <div className={'input-wrapper'}>
                        <input type={showPassword ? 'text' : 'password'} name={'password_confirmation'} placeholder={'Password Confirmation'} value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} className={passwordConfirmationError ? 'error' : ''} />
                        <label>{'Password Confirmation'}</label>   
                    </div>
                    {/* <div className={'input-wrapper'}>
                        <button type={'button'} onClick={(e) => handleOnShowPassword(e)}>{showPassword ? 'Hide Password' : 'Show Password'}</button>
                    </div> */}
                    <div className={'input-wrapper'}>
                        <input type={'submit'} value={'Submit'} disabled={submit || passwordConfirmationError || passwordError || password.length === 0 || emailAddress.length === 0 || passwordConfirmation.length === 0 ? 'disabled' : ''} />
                    </div>
                    <div className={'input-wrapper note-wrapper'}>
                        <Link to='/login' className={"note"}>Already have an account?</Link> | <Link to='/forgot-password' className={"note"}>Forgot password?</Link>
                    </div>
                </form>
            </div>
            <VerificationModal 
                isOpen={showVerification}
                title="Enter Verification Code"
                content="Code sent to your email." 
                cancel={setShowVerification} 
                confirm={VerifiedSubmit}
            />
        </>
    )
}
  
export default RegistrationForm;