import React from 'react';
import { Helmet } from "react-helmet";
import { AbsoluteLink } from '../utils/LinkUtils';
import './AboutUs.css';

function AboutUs() {
    return (
        <div className={"page about-us-page"}>
            <Helmet>
                <title>Project Alpha - About us</title>
                <meta name="description" content="About us descirption" />
                <link rel="canonical" href={AbsoluteLink({ path: '/about-us' })} />
            </Helmet>
            <h1>About Us</h1>
            <div className={"about-us-wrapper"}>
               
            </div>
        </div>
    )
}

export default AboutUs;