import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { AbsoluteLink } from '../utils/LinkUtils';

import LoginForm from '../components/LoginForm/LoginForm';
import './Login.css';

import { GlobalContext } from "../contexts/GlobalContext";

function Login() {
    const navigate = useNavigate();
    const { GlobalContextState } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null } = GlobalContextState;
    // console.log('Login', global_session, global_session_key)
    if (!global_session && global_session_key === null && account === null)  {
        // console.log('Login 1')
        return (
            <div className="page login-page">
                <Helmet>
                    <title>Project Alpha - Login</title>
                    <meta name="description" content="Login page descirption" />
                    <link rel="canonical" href={AbsoluteLink({ path: '/login' })} />
                </Helmet>
                <h1>Log In</h1>
                <div className={"login-wrapper"}>
                    <LoginForm />
                </div>
            </div>
        )
    } else {
        // console.log('Login 2')
        navigate(`/search`)
    }
}

export default Login;