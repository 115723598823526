import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import './Home.css';
import { GlobalContext } from "../contexts/GlobalContext";

function Home() {
    const { GlobalContextState } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null } = GlobalContextState;

    return (
        <>
            <Helmet>
                <title>Project Alpha - Home</title>
                <meta name="description" content="Home page descirption" />
                <link rel="canonical" href="http://test.com/" />
            </Helmet>
            <div className={"app-home"}>
                <header>
                    <h1>Company Name</h1>
                    <nav>
                    <ul>
                        <li><a href="/search">Search</a></li>
                        <li><a href="/about-us">About Us</a></li>
                        <li><a href="/register">Sign Up</a></li>
                        <li><a href="/login">Log in</a></li>
                    </ul>
                    </nav>
                </header>
                
                <img class="section-banner" width={800} height={420} src="https://assets-global.website-files.com/6009ec8cda7f305645c9d91b/60107ed479293a1144ffceab_6002086f72b7275b5801e725_website-hero-image.jpeg" alt="Section Banner" />

                <div class="hero">
                    <div class="hero-section">
                    <h2>Section 1</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <a href="#">Learn More</a>
                    </div>
                    
                    <div class="hero-section">
                    <h2>Section 2</h2>
                    <p>Phasellus fermentum magna ut nunc lacinia, vitae interdum massa tincidunt.</p>
                    <a href="#">Learn More</a>
                    </div>
                    
                    <div class="hero-section">
                    <h2>Section 3</h2>
                    <p>Curabitur consectetur nisl nec augue tristique, id maximus risus laoreet.</p>
                    <a href="#">Learn More</a>
                    </div>
                    
                    <div class="hero-section">
                    <h2>Section 4</h2>
                    <p>Vestibulum eu ipsum finibus, aliquam tellus sed, dignissim ipsum.</p>
                    <a href="#">Learn More</a>
                    </div>
                </div>
                
                <footer>
                    <p>&copy; 2023 Your Company. All rights reserved. | <a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms of Service</a></p>
                </footer>
            </div>
        </>
    )
}
  
export default Home;