import React, { useState, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// import Moment from 'react-moment';

import { AccountMessageConstant } from '../../constants/MessageConstants';
import { ValidateEmail } from '../../utils/ValidationUtils';
import './AccountInfo.css';

import { GlobalContext } from '../../contexts/GlobalContext';

import VerificationModal from '../../components/VerificationModal/VerificationModal';
import { VerifyRequest, VerifyCode } from '../../services/VerificationServices';

function AccountInfo(props) {
    const { enqueueSnackbar } = useSnackbar();

    const { GlobalContextDispatch } = useContext(GlobalContext);

    const { user_info={} } = props
    const { _id=null, email=null, emailValidated=false, regType=0, userType=0, userTypeEnum='USER', date_created=null, date_updated=null} = user_info;
    const [ edit, setEdit ] = useState(false);
    const [ submit, setSubmit ] = useState(false);

    const [ showVerification, setShowVerification ] = useState(false);

    const [ formEmail, setFormEmail ] = useState(email || null);
    const [ formRegType ] = useState(regType || 0);
    const [ formUserType ] = useState(userType || 0);
    const [ formEmailError, setFormEmailError ] = useState(false);
    const [ formEmailValidated, setFormEmailValidated ] = useState(emailValidated);
    const [ formUserTypeEnum, setFormUserTypeEnum ] = useState(userTypeEnum);
    const [ verifyId, setVerifyId ] = useState('');
    const [ updateType, setUpdateType ] = useState('USER_UPDATE_INFO');

    const handleClickEdit = (e) => {
        e.preventDefault();
        if (edit) {
            setFormEmail(email);
            setFormEmailValidated(emailValidated);
            setFormUserTypeEnum(userTypeEnum);
        }
        setEdit(!edit);
    }

    const handleClickClose = (e) => {
        e.preventDefault();
        setShowVerification(false)
    }
    
    const handleOnSubmit = (e) => {
        try {
            // console.log('handleOnSubmit', e)
            e.preventDefault();
            //do validation
            setSubmit(true);
            if (formEmail.trim() === '' || !ValidateEmail(formEmail)) {
                if (formEmail.trim() === '' || !ValidateEmail(formEmail)) {
                    enqueueSnackbar(formEmail.trim() === '' ? AccountMessageConstant.en.emailRequiredError : AccountMessageConstant.en.invalidEmailAddresError, { variant: 'error', autoHideDuration: 3000 });
                    setFormEmailError(true);
                } else {
                    setFormEmailError(false);
                }
                setSubmit(false);
                console.log('FALSE');
            } else {
                console.log('TRUE');
                // setEdit(false);
                // setFormEmailError(false);
                
                //send a request for verification code
                VerifyRequest({ type: updateType }).then(result => {
                    if (result.data.success === 1) {
                        setShowVerification(true);
                        setVerifyId(result.data.verification);
                    } else if (result.data.success === 0) {
                        enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }
                }).catch(err => {
                    console.log('VerificationRequest err', err)
                    if (err.response.data) {
                        if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                            enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                        }    
                    }
                });
            }
        } catch (err) {
            console.log('handleOnSubmit', err);
        }
    }

    const VerifiedSubmit = (code) => {
        try {
            console.log('VerifiedSubmit' , code);
            VerifyCode({ id: verifyId, code: code.toUpperCase(), type: updateType }).then(result => {
                if (result.data.success === 1) {
                    setEdit(false);
                    setFormEmailError(false);
                    GlobalContextDispatch({ 
                        type: 'USER_INFO_UPDATE', payload: { 
                            user_info_update: {
                                email: formEmail,
                                emailValidated: formEmailValidated,
                                userTypeEnum: formUserTypeEnum
                            }
                        } 
                    });
                    setShowVerification(false);
                } else if (result.data.success === 0) {
                    enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                }
            }).catch(err => {
                console.log('VerificationRequest err', err)
                if (err.response.data) {
                    if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }    
                }
            });
            
        } catch (err) {
            console.log('VerifiedSubmit err', err)
        }
    }

    useEffect(() => {
        setSubmit(false);
        if (formEmail && formEmail.trim() !== '' && ValidateEmail(formEmail)) {
            setFormEmailError(false);
        } else {
            setFormEmailError(true);
        }
    }, [formEmail, formEmailValidated, formUserTypeEnum])

    return (
        <>
            <form onSubmit={handleOnSubmit}>
                {
                    _id !== null
                    ?
                        <div className={'account-info'}>
                            
                            <ul>
                                <li className={'label'}>
                                    Email Address
                                </li>
                                <li className={'value'}>
                                    {
                                        edit
                                        ?
                                            <input type={"text"} name={"email"} value={formEmail} onChange={(e) => setFormEmail(e.target.value)} className={formEmailError ? 'error' : ''}  />
                                        :
                                            formEmail
                                    }
                                </li>
                                <li className={'label'}>
                                    Email Validated?
                                </li>
                                <li className={'value'}>
                                    {
                                        edit
                                        ?
                                            <select name={"email-validated"} onChange={(e) => setFormEmailValidated(e.target.value)}>
                                                <option value={false} selected={formEmailValidated ? 'selected' : ''}>No</option>
                                                <option value={true}  selected={formEmailValidated ? 'selected' : ''}>Yes</option>
                                            </select>
                                        :
                                            formEmailValidated
                                            ?
                                                'Yes'
                                            :
                                                'No'
                                    }
                                </li>
                                <li className={'label'}>
                                    User Type
                                </li>
                                <li className={'value'}>
                                    {
                                        edit
                                        ?
                                            <select name={"user-type-enum"} onChange={(e) => setFormUserTypeEnum(e.target.value)}>
                                                <option value={"USER"} selected={formUserTypeEnum === 'USER' ? 'selected' : ''}>USER</option>
                                                <option value={"ADMIN"} selected={formUserTypeEnum === 'ADMIN' ? 'selected' : ''}>ADMIN</option>
                                            </select>
                                        :
                                            formUserTypeEnum === 'ADMIN'
                                            ?
                                                'ADMIN'
                                            :
                                                'USER'
                                    }
                                </li>
                                {/* <li className={'label'}>
                                    Member since m, 
                                </li>
                                <li className={'value'}>
                                    {
                                        date_created
                                        ?
                                            <Moment>{date_created}</Moment>
                                        :
                                            null
                                    }
                                </li>
                                <li className={'label'}>
                                    Last updated
                                </li>
                                <li className={'value'}>
                                    {
                                        date_updated
                                        ?
                                            <Moment>{date_updated}</Moment>
                                        :
                                            null
                                    }
                                </li> */}
                            </ul>
                            <input type={"hidden"} name={"user-type"} value={formUserType} readOnly />
                            <input type={"hidden"} name={"reg-type"} value={formRegType} readOnly />
                            <div className='input-action-wrapper'>
                                <input type={'button'} value={edit ? 'Cancel' : 'Edit'} onClick={(e) => handleClickEdit(e)} />
                                {
                                    edit
                                    ?
                                        <input type={'submit'} value={'Submit'} disabled={submit || formEmail.length === 0 || (formEmail === email && formEmailValidated === emailValidated && formUserTypeEnum === userTypeEnum) || formEmailError ? 'disabled' : ''} />
                                    :
                                        null
                                }
                            </div>
                        </div>
                    :
                        null
                }
            </form>
            <VerificationModal 
                isOpen={showVerification}
                title="Enter Verification Code"
                content="Code sent to your email." 
                cancel={setShowVerification} 
                confirm={VerifiedSubmit}
            />
        </>
    )
    
}
  
export default AccountInfo;