import React from 'react';
import './ReactionVideoItemContent.css';

const ReactionVideoItemContent = ({ src }) => {

  return (
    <>
        <div className="item-content">
            <div className="item-info">
                <div className="item-avatar">

                </div>
                <div className="item-username">
                    @ronron2
                </div>
                <div className="item-follow">
                    +follow
                </div>
            </div>
            <div className="item-description">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </div>
        </div>
    </>
  );
};

export default ReactionVideoItemContent;