import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { AbsoluteLink } from '../utils/LinkUtils';
import './Account.css';

import { GlobalContext } from "../contexts/GlobalContext";
import AccountInfo from '../components/AccountInfo/AccountInfo';
import Devices from '../components/Devices/Devices';

import LoginContextProvider from '../contexts/LoginContext';
import LoginForm from '../components/LoginForm/LoginForm';

function Account() {
    const navigate = useNavigate();
    const { GlobalContextState } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null } = GlobalContextState;
    const { devices=[], user_info=null } = account || {};
    
    // useEffect(() => {
    //     console.log('useEffect 2 user_info_update')
    //     if (global_session && global_session_key && user_info_update) {
    //         console.log('user_info_update', user_info_update)
    //     }
    // }, [user_info_update])

    return (
        <div className={"page account-page"}>
            <Helmet>
                <title>Project Alpha - Account</title>
                <meta name="description" content="Account descirption" />
                <link rel="canonical" href={AbsoluteLink({ path: '/account' })} />
            </Helmet>
            <h1 className={"account-title-header"}>Account Information</h1>
            {
                global_session && global_session_key !== null && account !== null
                ?
                    <div className={"account-wrapper"}>
                        {
                            user_info
                            ?
                                <AccountInfo user_info={user_info} />
                            :
                                null
                        }
                        {
                            devices.length !== 0
                            ?
                                <>
                                    <h3 className={"account-sub-title-header"}>Device{devices.length !== 1 ? 's' : ''}</h3>
                                    <Devices devices={devices} />
                                </>    
                            :
                                null
                        }
                    </div>
                :
                    <LoginContextProvider>
                        <LoginForm />
                    </LoginContextProvider>
            }
        </div>
    )
}

export default Account;