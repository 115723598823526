import React, { createContext, useReducer, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoginReducer } from "./LoginReducer";
import { LoginState } from "./LoginState";
import { Login } from "../services/LoginServices";
import { GlobalContext } from "./GlobalContext";
import { useSnackbar } from 'notistack';

export const LoginContext = createContext();
const initialState = LoginState;

const LoginContextProvider = (props) => {
    console.log('LoginContextProvider');
    const navigate = useNavigate();
    const { redirect = '/account' } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { GlobalContextDispatch } = useContext(GlobalContext);
    const [LoginContextState, LoginContextDispatch] = useReducer(LoginReducer, initialState);
    const { email=null, password=null } = LoginContextState;

    useEffect(() => {
        if (email && password) {
            LoginContextDispatch({ type: 'SET_PROCESS', payload: { is_processing: true } })
            Login({ email: email, password: password }).then(result => {
                if (result.data.success === 1) {
                    GlobalContextDispatch({ type: "SET_SESSION", payload: result.data });
                    navigate(redirect);
                } else if (result.data.success === 0 && result.data.message && result.data.message !== '') { 
                    enqueueSnackbar(result.data.message, { variant: 'error', autoHideDuration: 3000 });
                }    
                LoginContextDispatch({ type: 'SET_PROCESS', payload: { is_processing: false } })
                LoginContextDispatch({ type: 'RESET_EMAIL_PASSORD' })
            }).catch(err => {
                if (err.response.data) {
                    if (typeof err.response.data.success !== undefined && err.response.data.success === 0 && err.response.data.message && err.response.data.message !== '') {
                        enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: 3000 });
                    }    
                }
                LoginContextDispatch({ type: 'SET_PROCESS', payload: { is_processing: false } })
                LoginContextDispatch({ type: 'RESET_EMAIL_PASSORD' })
            });
        }
    }, [email, password])

    return (
        <LoginContext.Provider value={{LoginContextState, LoginContextDispatch}}>
            {props.children}
        </LoginContext.Provider>
    );
}

export default LoginContextProvider;