import React, { useRef, useState, useEffect } from "react";

function CameraComponent() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [streaming, setStreaming] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [showCanvas, setShowCanvas] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (streaming && videoRef.current && canvasRef.current) {
        const aspectRatio = videoRef.current.videoHeight / videoRef.current.videoWidth;
        setWidth(window.innerWidth);
        setHeight(window.innerWidth * aspectRatio);
        canvasRef.current.width = width;
        canvasRef.current.height = height;
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [streaming, width, height]);

  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error("Error opening video camera.", error);
    }
  };

  const stopVideo = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach(function (track) {
      track.stop();
    });

    videoRef.current.srcObject = null;
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      ctx.drawImage(videoRef.current, 0, 0, width, height);
      setShowCanvas(true);
    }
  };

  const handleCanPlay = () => {
    if (!streaming) {
      const aspectRatio = videoRef.current.videoHeight / videoRef.current.videoWidth;
      setWidth(window.innerWidth);
      setHeight(window.innerWidth * aspectRatio);

      if (videoRef.current && canvasRef.current) {
        videoRef.current.width = width;
        videoRef.current.height = height;
        canvasRef.current.width = width;
        canvasRef.current.height = height;

        setStreaming(true);
      }
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <video ref={videoRef} onCanPlay={handleCanPlay} style={{ maxWidth: '100%' }}></video>
      <canvas 
        ref={canvasRef} 
        style={{ 
          maxWidth: '100%', 
          display: showCanvas ? 'block' : 'none',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2
        }} 
      />
      <div style={{ position: 'fixed', bottom: '10px', left: '50%', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'space-between', width: '90%' }}>
        <button onClick={startVideo}>Start Video</button>
        <button onClick={stopVideo}>Stop Video</button>
        <button onClick={captureImage}>Capture</button>
      </div>
    </div>
  );
}

export default CameraComponent;
