import axios from 'axios';
const itemApiVersion = process.env.REACT_APP_VERSION_API || '1.0';

export async function ItemGet(objData) {
    // console.log('ItemGet nodemon.json process.env.REACT_APP_VERSION_API', process.env.REACT_APP_VERSION_API)
    // console.log('ItemGet .env process.env.REACT_APP_VERSION_API', process.env.REACT_APP_VERSION_API)
    const { itemId=null, itemName="" } = objData;
    let endpoint = `/api/${itemApiVersion}/item/${itemId}/${itemName}`;
    const data = await axios.get(endpoint);
    return data;
}

export async function ItemLike(objData) {
    const { itemId=null, token=null } = objData;
    let endpoint = `/api/${itemApiVersion}/item/like/${itemId}`;
    const data = await axios.post(
        endpoint, 
        {},
        {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    return data;
}

export async function ItemUnlike(objData) {
    const { itemId=null, token=null } = objData;
    let endpoint = `/api/${itemApiVersion}/item/unlike/${itemId}`;
    const data = await axios.post(
        endpoint, 
        {},
        {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    return data;
}

export async function ItemPaid(objData) {
    console.log('ItemPaid', objData)
    const { itemId=null, token=null } = objData;
    let endpoint = `/api/${itemApiVersion}/item/paid/${itemId}`;
    const data = await axios.post(
        endpoint, 
        {},
        {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    return data;
}

export async function ItemUnpaid(objData) {
    const { itemId=null, token=null } = objData;
    let endpoint = `/api/${itemApiVersion}/item/unpaid/${itemId}`;
    const data = await axios.post(
        endpoint, 
        {},
        {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    return data;
}
