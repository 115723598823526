import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ItemRelativeLink } from '../../utils/LinkUtils';
import './ListItem.css';

function ListItem(props) {
    const { item={} } = props;
    const { _id=null, profileName=null, profileAka=null, profileAge=null, profileLocation=null, profilePastLocation=null, isPaid=false, isLike=false, likeCount=0, paidCount=0 } = item;
    
    return (
        <div className={"list-item"}>
            <div className={"image-wrapper"}>
                {/* <img src={"/logo512.png"} alt="thumb" /> */}
            </div>
            <div className={"header-wrapper"}>
                <h1 className={"item-title"}>{profileName}{profileAge ? ', ' + profileAge : ''}</h1>
                <span className={"item-sub-text"}>{profileLocation}</span>
                <span className={"item-sub-text"}>{profileAka}</span>
            </div>
            <p>
                {profilePastLocation} Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
            </p>
            <Link to={ItemRelativeLink({ item_id: _id, item_name: profileName })} className={"item-link"}>Read More...</Link>
        </div>
    )
}
  
export default ListItem;