import React from 'react';
import './ReactionVideoItemAction.css';

const ReactionVideoItemAction = ({ src }) => {

  return (
    <>
      <div className="item-action">
        <div className="item-icon">

        </div>
        <div className="item-text">
          489
        </div>
      </div>
      <div className="item-action">
        <div className="item-icon">

        </div>
        <div className="item-text">
          500K
        </div>
      </div>
      <div className="item-action">
        <div className="item-icon">

        </div>
        <div className="item-text">
          Share
        </div>
      </div>
    </>
  );
};

export default ReactionVideoItemAction;