import React, { useRef, useState, useEffect } from 'react';

const VideoRecorder = () => {
    const videoRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [facingMode, setFacingMode] = useState('user');
    const [stream, setStream] = useState(null);
    const [isStreamStarted, setIsStreamStarted] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [cameraAccessed, setCameraAccessed] = useState(false);

    let tempChunks = [];

    useEffect(() => {
        if (stream && videoRef.current) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    const detectVideoFormat = () => {
        const videoElement = document.createElement('video');
        const userAgent = navigator.userAgent.toLowerCase();
        const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
        const isIOS = /iphone|ipad|ipod/i.test(userAgent);
        if (isSafari || isIOS) {
            return 'mp4';
        }
        return videoElement.canPlayType('video/webm; codecs="vp8, vorbis"') !== '' ? 'webm' : 'mp4';
    };

    const videoFormat = detectVideoFormat();

    const startCameraStream = async (facing) => {
        try {
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: facing },
                audio: {
                    noiseSuppression: true,
                    echoCancellation: true,
                },
            });

            if (newStream && newStream.getTracks().length > 0) {
                setStream(newStream);
                setIsStreamStarted(true);
                setCameraAccessed(true);
                console.log('Camera accessed, stream started.');
                console.log('Stream tracks:', newStream.getTracks());
            } else {
                console.error('Stream has no tracks.');
            }
        } catch (err) {
            console.error('Error accessing webcam:', err);
        }
    };

    const startRecording = () => {
        if (!stream || !(stream instanceof MediaStream)) {
            console.error("Stream is not available or not a valid MediaStream for recording.");
            return;
        }

        tempChunks = [];
        setPreviewUrl(null);
        console.log('Starting new recording session. Chunks cleared.');

        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                tempChunks.push(event.data);
                console.log('Chunk received:', event.data.size);
            }
        };

        recorder.onstop = () => {
            console.log('Recording stopped.');
            if (tempChunks.length > 0) {
                const blob = createDynamicBlob(tempChunks);
                const videoUrl = URL.createObjectURL(blob);
                setDownloadUrl(videoUrl);
                setPreviewUrl(videoUrl);
                console.log('Download URL created:', videoUrl);
            } else {
                console.error('No data available to create a video.');
            }
        };

        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
    };

    const pauseRecording = () => {
        if (mediaRecorder && isRecording && !isPaused) {
            mediaRecorder.pause();
            setIsPaused(true);
        } else if (mediaRecorder && isPaused) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
            setIsPaused(false);
        }
    };

    const flipCamera = async () => {
        if (stream) {
            try {
                const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
                const videoTrack = stream.getVideoTracks()[0];
                const audioTrack = stream.getAudioTracks()[0];

                // Stop the current video track
                videoTrack.stop();

                // Get a new stream with the flipped camera
                const newStream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: newFacingMode },
                    audio: false, // We don't need a new audio track, keep the old one
                });

                const newVideoTrack = newStream.getVideoTracks()[0];
                
                // Create a new stream with the new video track and old audio track
                const updatedStream = new MediaStream([audioTrack, newVideoTrack]);

                // Update the stream state and facing mode
                setStream(updatedStream);
                setFacingMode(newFacingMode);
                console.log('Camera flipped successfully.');

                // The recording continues with the same MediaRecorder instance and new stream
            } catch (error) {
                console.error('Error flipping camera:', error);
            }
        }
    };

    const createDynamicBlob = (chunks) => {
        const mimeType = videoFormat === 'webm' ? 'video/webm' : 'video/mp4';
        const blob = new Blob(chunks, { type: mimeType });
        console.log(`Created blob with type: ${mimeType} and size:`, blob.size);
        return blob;
    };

    return (
        <div style={{ textAlign: 'center', position: 'relative' }}>
            {!isStreamStarted && (
                <div style={{ height: '300px', width: '100%', backgroundColor: '#ddd', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: '#888' }}>Video Stream Placeholder</p>
                </div>
            )}

            {isStreamStarted && (
                <div style={{ position: 'relative' }}>
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                        style={{
                            transform: 'scaleX(-1)',
                            width: '100%',
                            maxWidth: '500px',
                        }}
                    />
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: isRecording ? 'red' : 'gray',
                    }}>
                    </div>
                </div>
            )}

            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                {!isStreamStarted && (
                    <button onClick={() => startCameraStream(facingMode)}>Access Camera</button>
                )}
                {isStreamStarted && !isRecording && (
                    <button onClick={startRecording}>Start Recording</button>
                )}
                {isRecording && (
                    <>
                        <button onClick={pauseRecording}>
                            {isPaused ? 'Resume Recording' : 'Pause Recording'}
                        </button>
                        <button onClick={stopRecording}>Stop Recording</button>
                    </>
                )}
                {cameraAccessed && (
                    <button onClick={flipCamera}>Flip Camera</button>
                )}
            </div>

            {previewUrl && (
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <h3>Preview Recorded Video</h3>
                    <video
                        src={previewUrl}
                        controls
                        playsInline
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                            marginTop: '10px',
                        }}
                    />
                </div>
            )}

            {downloadUrl && (
                <div style={{ marginTop: '20px' }}>
                    <a href={downloadUrl} download={`recorded_video.${videoFormat}`}>
                        Download Video
                    </a>
                </div>
            )}
        </div>
    );
};

export default VideoRecorder;
