import React, { createContext, useReducer, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { ItemReducer } from "./ItemReducer";
import { ItemState } from "./ItemState";
import { ItemGet } from "../services/ItemServices";

export const ItemContext = createContext();
const initialState = ItemState;

const ItemContextProvider = (props) => {
    // console.log('ItemContextProvider', props)
    const { context=null } = props;
    const { item=null } = context || {};
    const { itemId="", itemName="" } = useParams();
    const [ItemContextState, ItemContextDispatch] = useReducer(ItemReducer, item ? context : initialState);

    useEffect(() => {
        console.log('useEffect once', itemId)
        // console.log('window.__initial_data__', typeof window.__initial_data__)

        // prevent to call api on initial load of the app - avoid calling api if page is ssr
        if (window.__initial_data__ === null) {
            const objData = {
                itemId: itemId, 
                itemName: itemName 
            }
            ItemGet(objData).then(result => {
                if (result.data.success === 1 && result.data.item !== null) {
                    ItemContextDispatch({ type: "SET_ITEM", payload: result.data });
                } 
            }).catch(err => {
                console.log('err.response.status', err.response.status)
                if (err.response.status === 404) {
                    ItemContextDispatch({ type: "PAGE_NOT_FOUND" });
                } 
                if (err.response.status === 500) {
                    ItemContextDispatch({ type: "SERVER_ERROR" });
                } 
                console.log("ItemGet", err);
            });
        } else {
            // set initial data to null
            window.__initial_data__ = null;
        }
    }, [])

    return (
        <ItemContext.Provider value={{ItemContextState, ItemContextDispatch}}>
            {props.children}
        </ItemContext.Provider>
    );
}

export default ItemContextProvider;