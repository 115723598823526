export const SearchState = {
    search_str: null,
    category_type: 'people',
    items: [],
    items_per_page: 10,
    total: 0,
    page: 1,
    total_page: 1,
    initial_load: true,
    server_error: false,
    page_not_found: false
}