export const ReactionCameraReducer = (state, action) => {
    console.log('ReactionCameraReducer', state, action)
    switch(action.type){
        case 'SET_CAMERA_LOADED': 
            return {
                ...state,
                isCameraLoaded: action.payload.bool,
            } 
        // case 'SET_CAMERA_ON': 
        //     return {
        //         ...state,
        //         isCameraOn: action.payload.isCameraOn,
        //         isRecording: true
        //     } 
        case 'SET_START_RECORDING':
            return {
                ...state,
                isCameraOn: true,
                isRecording: true,
                isData: false
            }    
        case 'SET_STOP_RECORDING':
            return {
                ...state,
                isRecording: false,
                isData: true
            }
        case 'SET_REDO':
            return {
                ...state,
                isRedo: action.payload.bool
            }
        case 'SET_POSTING':
            return {
                ...state,
                isPosting: action.payload.isPosting,
                isData: false
            }
        case 'SET_COMPLETE_POSTING':
            return {
                ...state,
                isPosting: false,
                isData: false,
                isCameraOn: false
            } 
        case 'SET_FACE_MODE':
            return {
                ...state,
                isFaceMode: action.payload.isFaceMode,
            }                       
        default:
            return state    
    }
}