import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { LoginContext  } from '../../contexts/LoginContext';
import { LoginMessageConstant } from '../../constants/MessageConstants';
import { ValidateStringNotEmpty } from '../../utils/ValidationUtils';
import './LoginForm.css';

function LoginForm(props) {
    const { enqueueSnackbar } = useSnackbar();
    const { LoginContextState, LoginContextDispatch } = useContext(LoginContext);
    const { is_processing = false } = LoginContextState;
    const { path='/login', method='post' } = props;
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ emailAddressError, setEmailAddressError ] = useState(false);
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ submit, setSubmit ] = useState(false);

    const handleOnSubmit = (e) => {
        // console.log(e)
        try {
            e.preventDefault();
            //do validation
            setSubmit(true);
            if (!ValidateStringNotEmpty(emailAddress) || !ValidateStringNotEmpty(password)) {
                if (!ValidateStringNotEmpty(emailAddress)) {
                    enqueueSnackbar(LoginMessageConstant.en.emailRequiredError, { variant: 'error', autoHideDuration: 3000 });
                    setEmailAddressError(true);
                } else {
                    setEmailAddressError(false);
                }
    
                if (!ValidateStringNotEmpty(password)) {
                    enqueueSnackbar(LoginMessageConstant.en.passwordRequiredError, { variant: 'error', autoHideDuration: 3000 });
                    setPasswordError(true);
                } else {
                    setPasswordError(false);
                }
                console.log('FALSE');
                setSubmit(false);
                return false;
            } else {
                console.log('TRUE');
                setEmailAddressError(false);
                setPasswordError(false);
                LoginContextDispatch({ type: 'SET_LOGIN', payload: { email: emailAddress, password: password } });
                // setSubmit(false);
                return true;
            }
        } catch (err) {
            console.log('handleOnSubmit', err)
        }
    }

    const handleOnShowPassword = (e) => {
        setShowPassword(!showPassword);
    }
    
    useEffect(() => {
        console.log('login is_processing', is_processing)
        if (!is_processing) {
            setSubmit(false);
        }
    }, [is_processing])

    return (
        <div className={'login-form'}>
            <form action={path} method={method} onSubmit={handleOnSubmit}>
                <div className={'input-wrapper'}>
                    <input type={'text'} name={'email_address'} placeholder={'Email Address'} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} className={emailAddressError ? 'error' : ''} />
                    <label>{'Email Address'}</label>   
                </div>
                <div className={'input-wrapper'}>
                    <input type={showPassword ? 'text' : 'password'} name={'password'} placeholder={'Password'} value={password} onChange={(e) => setPassword(e.target.value)} className={passwordError ? 'error' : ''} />
                    <label>{'Password'}</label> 
                    <span onClick={(e) => handleOnShowPassword(e)} class="material-symbols-rounded">{ showPassword ? 'visibility' : 'visibility_off' }</span> 
                </div>
                {/* <div className={'input-wrapper'}>
                    <button type={'button'} onClick={(e) => handleOnShowPassword(e)}>{showPassword ? 'Hide Password' : 'Show Password'}</button>
                </div> */}
                <div className={'input-wrapper'}>
                    <input type={'submit'} value={'Submit'} disabled={submit || passwordError || password.length === 0 || emailAddress.length === 0 ? 'disabled' : ''} />
                </div>
                <div className={'input-wrapper note-wrapper'}>
                    <Link to='/register' className={"note"}>Register</Link> | <Link to='/forgot-password' className={"note"}>Forgot password?</Link>
                </div>
            </form>
        </div>
    )
}
  
export default LoginForm;