import React, { createContext, useReducer, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { SearchReducer } from "./SearchReducer";
import { SearchState } from "./SearchState";
import { SearchGet } from "../services/SearchServices";

export const SearchContext = createContext();
const initialState = SearchState;

const SearchContextProvider = (props) => {
    // console.log('SearchContextProvider', props);
    const { context=null } = props;
    const { items_per_page=10 } = context || {};
    const { pageNo="", categoryStr="", searchStr=null } = useParams();
    const [ SearchContextState, SearchContextDispatch ] = useReducer(SearchReducer, context ? context : initialState);

    useEffect(() => {
        if (window.__initial_data__ === null && searchStr && searchStr !== '') {
            const objData = {
                pageNo: pageNo, 
                itemsPerPage: items_per_page,
                categoryStr: categoryStr, 
                searchStr: searchStr
            }
            SearchGet(objData).then(result => {
                // console.log('useEffect objData', objData)
                if (result.data.success === 1 && result.data.items !== null) {
                    SearchContextDispatch({ type: "SET_SEARCH_RESULTS", payload: result.data });
                } 
            }).catch(err => {
                console.log('err.response.status', err.response.status)
                if (err.response.status === 404) {
                    SearchContextDispatch({ type: "PAGE_NOT_FOUND" });
                } 
                if (err.response.status === 500) {
                    SearchContextDispatch({ type: "SERVER_ERROR", });
                }
                console.log("SearchGet", err);
            });
        } else {
            // set initial data to null
            window.__initial_data__ = null;
        }
    }, [pageNo, categoryStr, searchStr])

    return (
        <SearchContext.Provider value={{SearchContextState, SearchContextDispatch}}>
            {props.children}
        </SearchContext.Provider>
    );
}

export default SearchContextProvider;