export const ForgotPasswordReducer = (state, action) => {
    switch(action.type){
        case 'UPDATE_PASSWORD': 
            return {
                ...state,
                id: action.payload.id,
                code: action.payload.code,
                email: action.payload.email,   
                password: action.payload.password
            }
        case 'RESET_FIELDS':
            return {
                ...state,
                id: null,
                code: null,
                email: null,   
                password: null
            }                
        default:
            return state    
    }
}