import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import './ReactionVideos.css';
import { GlobalContext } from "../contexts/GlobalContext";
import ReactionVideoList from "../components/ReactionVideoList/ReactionVideoList";
import ReactionNavigation from '../components/ReactionNavigation/ReactionNavigation';
import ReactionCamera from '../components/ReactionCamera/ReactionCameraActions';
import ReactionCameraContext from '../contexts/ReactionCameraContext';

function ReactionVideos() {
    const { GlobalContextState } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null } = GlobalContextState;

    const data = [
        {
            "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1661229160013.mp4",
            "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1661229160013.jpg"
        },
        {
            "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1634357689314.mp4",
            "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1634357689314.jpg"
        },
        {
            "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646470986690.mp4",
            "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646470986690.jpg"
        },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60d42e7b7ccf630004446fc3/video-1644299104619.mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60d42e7b7ccf630004446fc3/video-1644299104619.jpg"
        // },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/trim.7C14BEF1-07E9-47A7-8386-3D552879860F%20(3).mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/trim.7C14BEF1-07E9-47A7-8386-3D552879860F%20(3).jpg"
        // },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60d95f5be66073000429d221/video-1637085302741.mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60d95f5be66073000429d221/video-1637085302741.jpg"
        // },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1661229160013.mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1661229160013.jpg"
        // },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1634357689314.mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1634357689314.jpg"
        // },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646470986690.mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646470986690.jpg"
        // },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60d42e7b7ccf630004446fc3/video-1644299104619.mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60d42e7b7ccf630004446fc3/video-1644299104619.jpg"
        // },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/trim.7C14BEF1-07E9-47A7-8386-3D552879860F%20(3).mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/trim.7C14BEF1-07E9-47A7-8386-3D552879860F%20(3).jpg"
        // },
        // {
        //     "videoUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60d95f5be66073000429d221/video-1637085302741.mp4",
        //     "imageUrl" : "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60d95f5be66073000429d221/video-1637085302741.jpg"
        // }
    ]

    return (
        <>
            <Helmet>
                <title>Project Alpha - Reaction Videos</title>
                <meta name="description" content="Reaction videos page descirption" />
                <link rel="canonical" href="http://www.com/VIDEO_ID/reactions" />
            </Helmet>
            
            <ReactionNavigation />
            
            <ReactionCameraContext>
                <ReactionVideoList data={data} />
                <ReactionCamera />
            </ReactionCameraContext>
        </>
    )
}
  
export default ReactionVideos;