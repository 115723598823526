import axios from 'axios';
const userApiVersion = process.env.REACT_APP_VERSION_API || '1.0';

export async function GetUserInfo(objData) {
    const { token = null } = objData;
    const data = await axios.get(
        `/api/${userApiVersion}/user`, 
        {},
        {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        }
    );
    return data;
}

export async function UpdateUserInfo(objData, token) {
    // for later use - revisit for security evaluation
    // const { email = '', emailValidated = false, password = '', userTypeEnum = 'USER', userType = 0, regType = 0 } = objData;
    // const { email = '', emailValidated = false, userTypeEnum = 'USER' } = objData;
    const data = await axios.post(
        `/api/${userApiVersion}/user`, 
        objData,
        {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        }
    );
    return data;
}

export async function RemoveUserDevice(objData, token) {
    console.log('RemoveUserDevice', objData, token)
    // for later use - revisit for security evaluation
    // const { email = '', emailValidated = false, password = '', userTypeEnum = 'USER', userType = 0, regType = 0 } = objData;
    // const { email = '', emailValidated = false, userTypeEnum = 'USER' } = objData;
    const data = await axios.post(
        `/api/${userApiVersion}/user/device`, 
        objData,
        {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        }
    );
    return data;
}