export const GlobalReducer = (state, action) => {
    switch(action.type){
        case 'GLOBAL_INITIAL_LOAD': 
            return {
                ...state,
                global_initial_load: action.payload.global_initial_load,
                context: action.payload.context
            }
        case 'SET_SESSION': 
            return {
                ...state,
                global_session_redirect: true,   
                global_session: true,   
                global_session_key: action.payload.token,
                account: action.payload.account  
            }
        case 'LOGOUT_SESSION': 
            return {
                ...state,  
                global_session_redirect: true,   
                global_session: false,   
                global_session_key: null,  
                account: null
            }    
        case 'UPDATE_SESSION': 
            return {
                ...state,
                global_initial_load: false,
                global_session_redirect: false, 
                global_session: action.payload.session,   
                global_session_key: action.payload.token,  
                account: action.payload.account  
            }    
        case 'PAGE_NOT_FOUND':
            return {
                ...state,
                global_page_not_found: action.payload.global_page_not_found
            }
        case 'SERVER_ERROR':
            return {
                ...state,
                global_server_error: action.payload.global_server_error
            } 
        case 'USER_INFO_UPDATE':
            return {
                ...state,
                user_info_update: action.payload.user_info_update  
            }
        case 'RESET_USER_INFO_UPDATE':
            return {
                ...state,
                user_info_update: null  
            } 
        case 'DEVICE_REMOVE':
            return {
                ...state,
                device_remove: action.payload.device_remove  
            }
        case 'RESET_DEVICE_REMOVE':
            return {
                ...state,
                device_remove: null  
            }  
        case 'SHOW_PROCESS_MESSAGE':
            return {
                ...state,
                process_message_show: true,
                process_message: action.payload.process_message  
            }  
        case 'HIDE_PROCESS_MESSAGE':
            return {
                ...state,
                process_message_show: false,
                process_message: null  
            }   
        case 'SHOW_NAVIGATION':
            return {
                ...state,
                show_navigation: true
            }  
        case 'HIDE_NAVIGATION':
            return {
                ...state,
                show_navigation: false
            }
        case 'SHOW_FOOTER':
            return {
                ...state,
                show_footer: true
            }  
        case 'HIDE_FOOTER':
            return {
                ...state,
                show_footer: false
            }                    
        default:
            return state    
    }
}