import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import './Videos.css';
import { GlobalContext } from "../contexts/GlobalContext";
import FullScreenVideoList from "../components/FullScreenVideoList/FullScreenVideoList";

function Videos() {
    const { GlobalContextState } = useContext(GlobalContext);
    const { global_session = false, global_session_key = null, account = null } = GlobalContextState;

    const videoList = [
        "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1671088454643.mp4",
        "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/60f61fc2be0c3e259cdfcc34/video-1668877254295.mp4",
        "https://stage-0.dragtofans.com/dtf2021/video/upload/c_limit,h_1024,w_768/du_10/v1/videos/users/62229313f079820004cee3a1/video-1646434163316.mp4",
        // add more video URLs here
    ];

    return (
        <>
            <Helmet>
                <title>Project Alpha - Videos</title>
                <meta name="description" content="Videos page descirption" />
                <link rel="canonical" href="http://test.com/" />
            </Helmet>
            <FullScreenVideoList videoList={videoList} />
        </>
    )
}
  
export default Videos;