import React, { useState, useContext } from 'react';
// import Moment from 'react-moment';
import './DeviceInfo.css';

import { GlobalContext } from '../../contexts/GlobalContext';

import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

function DeviceInfo(props) {
    // console.log('DeviceInfo', props)
    const { device={}, index=0 } = props;
    const { _id=null, user=null, token=null, ip=null, ip2=null, browser=null, os=null, platform=null, version=null, source=null, date_created=null, date_updated=null } = device;
    const { GlobalContextDispatch } = useContext(GlobalContext);
    const [ submit, setSubmit ] = useState(false);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ isDeleted, setIsDeleted ] = useState(false);

    const removeDevice = () => {  
        // console.log('remove device')
        try {
            setSubmit(true, setIsDeleted(true));
            GlobalContextDispatch({ 
                type: 'DEVICE_REMOVE', payload: { 
                    device_remove: {
                        id: _id
                    }
                } 
            }) 
        } catch (err) {
            console.log('handleClickRemoveDevice', err);
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleConfirm = () => {
        setIsModalOpen(false);
        removeDevice();
    }

    return (
        <>
            {
                _id !== null && !isDeleted
                ?
                    <div key={`device-info-${index}`} className={'device-info'}>
                        <ul>
                            <li className={'label'}>
                                IP
                            </li>
                            <li className={'value'}>
                                {ip}
                            </li>
                            <li className={'label'}>
                                BROWSER
                            </li>
                            <li className={'value'}>
                                {browser}
                            </li>
                            <li className={'label'}>
                                OS
                            </li>
                            <li className={'value'}>
                                {os}
                            </li>
                            <li className={'label'}>
                                PLATFORM
                            </li>
                            <li className={'value'}>
                                {platform}
                            </li>
                            <li className={'label'}>
                                VERSION
                            </li>
                            <li className={'value'}>
                                {version}
                            </li>
                            <li className={'label'}>
                                SOURCE
                            </li>
                            <li className={'value'}>
                                {source}
                            </li>
                            {/* <li className={'label'}>
                                CREATED
                            </li>
                            <li className={'value'}>
                                {
                                    date_created
                                    ?
                                        <Moment>{date_created}</Moment>
                                    :
                                        null
                                }
                            </li>
                            <li className={'label'}>
                                UPDATED
                            </li>
                            <li className={'value'}>
                                {
                                    date_updated
                                    ?
                                        <Moment>{date_updated}</Moment>
                                    :
                                        null
                                }
                            </li> */}
                        </ul>
                        <button type={'button'} onClick={(e) => setIsModalOpen(true)} disabled={submit ? 'disabled' : ''}>Remove Device</button>
                    </div>
                :
                    null
            }
            <ConfirmationModal
                isOpen={isModalOpen}
                title="Are you sure?"
                content="This device will be removed."
                cancel={handleCancel}
                confirm={handleConfirm}
            />
        </>
    )
    
}
  
export default DeviceInfo;