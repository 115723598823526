export const focusNextInput = (e) => {
    // Get all input elements
    let inputs = Array.from(document.querySelectorAll('input'));
    // Get the current element's index
    let currentIndex = inputs.indexOf(e.target);
    console.log('currentIndex', currentIndex, inputs[currentIndex].value, e.target.value)
    console.log('inputs[currentIndex + 1]',inputs[currentIndex].getAttribute('tabindex'))
    // If the current element is not the last one, focus on the next input field
    if (currentIndex < inputs.length - 1 && inputs[currentIndex].value !== '' && inputs[currentIndex].getAttribute('tabindex') < 6) {
        inputs[currentIndex + 1].focus();
    } else {
        // Else focus on the first input field
        // inputs[0].focus();
    }
}