import React, { useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

//pages
import Home from '../pages/Home';
import Search from '../pages/Search';
import ItemPage from '../pages/ItemPage';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import Registration from '../pages/Registration';
import Account from '../pages/Account';
import AboutUs from '../pages/AboutUs';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import Faqs from '../pages/Faqs';
import PageNotFound from '../pages/PageNotFound';
import Videos from '../pages/Videos';
import ReactionVideos from '../pages/ReactionVideos';
import ReactionCameraPage from '../pages/ReactionCameraPage';
import CameraPage from '../pages/CameraPage';
import MagicCardPage from '../pages/MagicCardPage'
import ServerError from '../pages/ServerError';

// context provider
import SearchContextProvider from '../contexts/SearchContext';
import ItemContextProvider from '../contexts/ItemContext';
import RegisterContextProvider from '../contexts/RegisterContext';
import LoginContextProvider from '../contexts/LoginContext';
import ForgotPasswordContextProvider from '../contexts/ForgotPasswordContext';
import MagicCardProvider from '../contexts/MagicCardContext';
import { GlobalContext } from '../contexts/GlobalContext';

// tracking
import { PageView, initGA } from '../utils/Ga4TrackingUtils';

// components
// import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
// import DebubInfo from '../components/DebugInfo/DebugInfo';
import ProcessMessageModal from '../components/ProcessMessageModal/ProcessMessageModal';

function AppRoutes(props) {
  const location = useLocation();
  const { context=null } = props;
  const { GlobalContextState, GlobalContextDispatch } = useContext(GlobalContext);
  const { process_message_show=false, process_message=null } = GlobalContextState;

  useEffect(() => {
    // console.log('AppRoutes useEffect once', process.env.REACT_APP_UA_ID)
    try {
        console.log('initGA', process.env.REACT_APP_UA_ID)
        initGA(process.env.REACT_APP_UA_ID)
    } catch (e) {
        console.log('err', e)
    }   
  }, [])


  useEffect(() => {
    // console.log('AppRoutes useEffect location', location, process.env.REACT_APP_UA_ID)
    // console.log('location', location);
    // NOTE: location "/reactions...." - hide default navigation - reaction app is temp only with the this code
    PageView();
    window.scrollTo(0, 0);
    if (location.pathname === '' || location.pathname === '/' || location.pathname.indexOf('/magic-card') !== -1 || location.pathname.indexOf('/camera') !== -1 || location.pathname.indexOf('/reaction') !== -1) {
      GlobalContextDispatch({ type: 'HIDE_NAVIGATION' });
      GlobalContextDispatch({ type: 'HIDE_FOOTER' });
    } else {
      GlobalContextDispatch({ type: 'SHOW_NAVIGATION' });
      GlobalContextDispatch({ type: 'SHOW_FOOTER' });
    }
  }, [location])

  return (
    <>
      {/* <ScrollToTop /> */}
      <Navigation />
      <Routes props>
        
        {/* add routes for error to match ssr page if any error */}
        {
          context && context.serverError
          ?
            // console.log('context.serverError', context)
            <Route path="*" element={ <ServerError/> } />
          :
            context && context.pageNotFound
            ?
              <Route path="*" element={ <PageNotFound /> } />
            :
              <>
                <Route path="/" element={ <Home/> } />

                <Route path="/video/:videoId" element={ <Videos /> } />
                <Route path="/videos" element={ <Videos /> } />
                
                <Route path="/reaction/video/:videoId" element={ <ReactionVideos /> } />
                <Route path="/reaction/videos" element={ <ReactionVideos /> } />  
                <Route path="/reaction/camera" element={ <ReactionCameraPage /> } />

                <Route path="/camera" element={ <CameraPage /> } />

                {/* endpoint sample /search/people/1/john-smith */}
                <Route path="/search/:categoryStr/:pageNo/:itemsPerPage/:searchStr" element={ 
                  <SearchContextProvider context={context}>
                    <Search /> 
                  </SearchContextProvider>
                } />

                <Route path="/search" element={ 
                  <SearchContextProvider context={context}>
                    <Search />
                  </SearchContextProvider> 
                } />

                {/* endpoint sample /item/33934893893/john-smith */}
                <Route path="/item/:itemId/:itemName" element={                             
                  <ItemContextProvider context={context}>
                    <ItemPage />
                  </ItemContextProvider>
                } />

                <Route path="/login" element={ 
                  <LoginContextProvider redirect="/search">
                    <Login/> 
                  </LoginContextProvider>
                } />
                
                <Route path="/sign-up" element={ 
                  <RegisterContextProvider>
                    <Registration/> 
                  </RegisterContextProvider>
                } />

                <Route path="/register" element={ 
                  <RegisterContextProvider>
                    <Registration/> 
                  </RegisterContextProvider>
                } />

                <Route path="/forgot-password" element={
                    <ForgotPasswordContextProvider>
                      <ForgotPassword /> 
                    </ForgotPasswordContextProvider>
                } />

                <Route path="/account" element={ <Account/> } />

                <Route path="/about-us" element={ <AboutUs/> } />
                <Route path="/about" element={ <AboutUs/> } />

                <Route path="/terms" element={ <Terms/> } />
                <Route path="/privacy" element={ <Privacy/> } />
                <Route path="/faqs" element={ <Faqs/> } />
                
                <Route path="/404" element={ <PageNotFound/> } />
                <Route path="/server-error" element={ <ServerError/> } />


                <Route path="/magic-card" element={ 
                  <MagicCardProvider>
                    <MagicCardPage /> 
                  </MagicCardProvider>
                } />

                <Route path="*" element={ <PageNotFound /> } />
              </>
        }
      </Routes>
      {
        process_message_show && process_message
        ?
          <ProcessMessageModal message={process_message} />
        :
          null
      }
      <Footer />
      {/* <DebubInfo /> */}
    </>
  )
}

export default AppRoutes;