import React, { useState, useEffect } from 'react';
import './VerificationModal.css';
import { ValidateStringNotEmpty } from '../../utils/ValidationUtils';
import { focusNextInput } from '../../utils/CommonUtils';

const VerificationModal = ({ isOpen = true, title = "Enter Verification Code", content = "Code sent to your email.", cancel, confirm }) => {

  const [ code1, setCode1 ] = useState('');
  const [ code2, setCode2 ] = useState('');
  const [ code3, setCode3 ] = useState('');
  const [ code4, setCode4 ] = useState('');
  const [ code5, setCode5 ] = useState('');
  const [ code6, setCode6 ] = useState('');
  const [ submit, setSubmit ] = useState(false);
  const [ codeError, setCodeError ] = useState(false);

  const handleOnSubmit = (e) => {
    try {
      e.preventDefault();
      if (ValidateStringNotEmpty(code1) && ValidateStringNotEmpty(code2) && ValidateStringNotEmpty(code3) && ValidateStringNotEmpty(code4) && ValidateStringNotEmpty(code5) && ValidateStringNotEmpty(code6)) {
        setCodeError(false);
        setSubmit(true);
        confirm(`${code1}${code2}${code3}${code4}${code5}${code6}`)
      } else {
        setCodeError(true);
        setSubmit(false);
      }   
    } catch(err) {
      console.log('handleOnSubmit err', err)
      setCodeError(false);
      setSubmit(false);
    }
  }

  const handleOnClose = (e) => {
    try {
      e.preventDefault();
      cancel(false);
    } catch(err) {
      console.log('handleOnClose err', err)
    }
  }

  useEffect(() => {
    setSubmit(false);
    if (ValidateStringNotEmpty(code1) && ValidateStringNotEmpty(code2) && ValidateStringNotEmpty(code3) && ValidateStringNotEmpty(code4) && ValidateStringNotEmpty(code5) && ValidateStringNotEmpty(code6)) {
      setCodeError(false);
    }
  }, [code1, code2, code3, code4, code5, code6])

  if (!isOpen) {
    return null;
  }
  return (
    <div className={"verification-modal"}>
      <div className={"verification-content-wrapper"}>
        <p>{title}</p>
        <span>{content}</span>
        <div className={"code-input"}>
          <div className={'input-wrapper'}>
              <input type={'text'} name={'code_1'} maxLength={1} size={1} tabIndex={1} value={code1} onChange={(e) => setCode1(e.target.value, focusNextInput(e))} className={codeError ? 'error' : ''} />   
          </div>
          <div className={'input-wrapper'}>
              <input type={'text'} name={'code_2'} maxLength={1} size={1} tabIndex={2} value={code2} onChange={(e) => setCode2(e.target.value, focusNextInput(e))} className={codeError ? 'error' : ''} />
          </div>
          <div className={'input-wrapper'}>
              <input type={'text'} name={'code_3'} maxLength={1} size={1} tabIndex={3} value={code3} onChange={(e) => setCode3(e.target.value, focusNextInput(e))} className={codeError ? 'error' : ''} />
          </div>
          <div className={'input-wrapper'}>
              <input type={'text'} name={'code_4'} maxLength={1} size={1} tabIndex={4} value={code4} onChange={(e) => setCode4(e.target.value, focusNextInput(e))} className={codeError ? 'error' : ''} />
          </div>
          <div className={'input-wrapper'}>
              <input type={'text'} name={'code_5'} maxLength={1} size={1} tabIndex={5} value={code5} onChange={(e) => setCode5(e.target.value, focusNextInput(e))} className={codeError ? 'error' : ''} />
          </div>
          <div className={'input-wrapper'}>
              <input type={'text'} name={'code_6'} maxLength={1} size={1} tabIndex={6} value={code6} onChange={(e) => setCode6(e.target.value, focusNextInput(e))} className={codeError ? 'error' : ''} />
          </div>
        </div>
        <input type={"button"} onClick={(e) => handleOnClose(e)} value={"Cancel"} />
        {/* <input type={'submit'} value={'Confirm'} disabled={submit || passwordError || password.length === 0 || emailAddress.length === 0 ? 'disabled' : ''} /> */}
        <input type={"submit"} onClick={(e) => handleOnSubmit(e)} disabled={submit || codeError ? 'disabled' : ''} value={"Submit"} />
      </div>
    </div>
  );
};

export default VerificationModal;
