export const SearchReducer = (state, action) => {
    switch(action.type){
        case 'SET_SEARCH': 
            return {
                ...state,
                searchStr: action.payload.searchStr,   
            }
        case 'SET_SEARCH_RESULTS': 
            console.log('SET_SEARCH_RESULTS action.payload', action.payload)
            return {
                ...state,
                search_str: action.payload.search_str,
                category_type: action.payload.category_type,
                items: action.payload.items,  
                items_per_page: action.payload.items_per_page,  
                total: action.payload.total,  
                page: action.payload.page,  
                total_page: action.payload.total_page,
                initial_load: false   
            }
        case 'PAGE_NOT_FOUND':
            return {
                ...state,
                page_not_found: true
            }
        case 'SERVER_ERROR':
            return {
                ...state,
                server_error: true
            }       
        default:
            return state    
    }
}