export function ValidateEmail(data='') {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.trim())) {
        return (true)
    }
    return (false)
}

export function ValidatePassword(data='') {
    if (/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(data.trim())) {
        return (true)
    }
    return (false)
}

export function ValidateStringNotEmpty(data='') {
    if (data.trim() !== '') {
        return (true)
    }
    return (false)
}