import React from 'react';
import './ReactionNavigation.css';

const ReactionNavigation = ({ src }) => {

  return (
    <div className="reaction-navigation">
        <div className="reaction-navigation-grid">
            <div className="reaction-navigation-button">
                <div className="reaction-navigation-icon">
                    1
                </div>
            </div>
            <div className="reaction-navigation-button">
                <div className="reaction-navigation-icon">
                    2
                </div>
            </div>
            <div className="reaction-navigation-button">
                <div className="reaction-navigation-icon reaction-icon-plus">
                    +
                </div>
            </div>
            <div className="reaction-navigation-button">
                <div className="reaction-navigation-icon">
                    3
                </div>
            </div>
            <div className="reaction-navigation-button">
                <div className="reaction-navigation-icon">
                    4
                </div>
            </div>
        </div>
    </div>
  );
};

export default ReactionNavigation;

