import React, { useContext } from 'react';
import './ReactionVideoItem.css'; // Importing the CSS for this component
import ReactionBubbleList from '../ReactionBubbleList/ReactionBubbleList';
import ReactionVideoItemAction from '../ReactionVideoItemAction/ReactionVideoItemAction';
import ReactionVideoItemContent from '../ReactionVideoItemContent/ReactionVideoItemContent';
import ReactionCameraButton from '../ReactionCameraButton/ReactionCameraButton';
import ReactionCameraView from '../ReactionCameraView/ReactionCameraView';
import ReactionCameraActions from '../ReactionCamera/ReactionCameraActions';

import { ReactionCameraContext } from '../../contexts/ReactionCameraContext';

const ReactionVideoItem = ({ src }) => {

  const { ReactionCameraContextState } = useContext(ReactionCameraContext);
  const { isCameraLoaded, isCameraOn, isRecording, isPosting, isData } = ReactionCameraContextState;

  // console.log('src',src)
  return (
    <div className="grid-container">

      <div className="grid-item-top">
        <ReactionBubbleList />
      </div>

      <div className="grid-item-left">
        {
          isCameraLoaded && isCameraOn || isRecording || isPosting || isData
          ?
            <ReactionCameraView />
          :
            <ReactionCameraButton />
        }
      </div>

      <div className="grid-item-right">
        {
          isCameraLoaded && isCameraOn || isRecording || isPosting || isData
          ?
            <ReactionCameraActions />
          :
            <ReactionVideoItemAction />
        }
      </div>

      <div className="grid-item-bottom">
        <ReactionVideoItemContent />
      </div>

      <div className="grid-item">
        <div className="item-video">
          <img
            src={src.imageUrl}
            alt="image background blur"
            className="video-image-blur"
          />

          <img
            src={src.imageUrl}
            alt="image background"
            className="video-image"
          />

          <video
            loop 
            muted
            autoPlay
            playsInline 
            className="video"     
            preload="auto"
            src={src.videoUrl}
            type="video/mp4"
          />

        </div>
      </div>
    </div>
  );
};

export default ReactionVideoItem;