import React, { useState, createContext, useReducer, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactionCameraReducer } from "./ReactionCameraReducer";
import { ReactionCameraState } from "./ReactionCameraState";
// import { Register } from "../services/ReactionVideoServices";
// import { GlobalContext } from "./GlobalContext";
// import { useSnackbar } from 'notistack';

export const ReactionCameraContext = createContext();
const initialState = ReactionCameraState;

const ReactionCameraContextProvider = (props) => {
    // const navigate = useNavigate();
    // const { redirect = '/account' } = props;
    // const { enqueueSnackbar } = useSnackbar();
    // const { GlobalContextDispatch } = useContext(GlobalContext);
    const [ ReactionCameraContextState, ReactionCameraCotextDispatch ] = useReducer(ReactionCameraReducer, initialState);
    const { isCameraLoaded=false, isCameraOn=false, isRecording=false, isRedo=false, isFaceMode=true, isPosting=false, isData=false } = ReactionCameraContextState;
    // const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        console.log('isCameraOn context',isCameraOn)
    }, [isCameraOn])

    useEffect(() => {
        console.log('isPosting context',isPosting)
        if (isPosting) {

            const timeOut = setTimeout(() => {
                ReactionCameraCotextDispatch({ type: "SET_COMPLETE_POSTING" }); 
                // setSeconds(prevSeconds => prevSeconds + 1); // Increments the seconds state by one every second
            }, 3000); // Sets up an interval to fire every 1000 milliseconds (1 second)
        
            return () => clearTimeout(timeOut);     
        }
    }, [isPosting])

    useEffect(() => {
        console.log('isRecording context',isRecording)
    }, [isRecording])

    return (
        <ReactionCameraContext.Provider value={{ReactionCameraContextState, ReactionCameraCotextDispatch}}>
            {props.children}
        </ReactionCameraContext.Provider>
    );
}

export default ReactionCameraContextProvider;