import React from 'react';
import ReactionVideoItem from '../ReactionVideoItem/ReactionVideoItem';
import './ReactionVideoList.css'; // Importing the CSS for this component

const ReactionVideoList = ({ data }) => {
  return (
    <>
      {
        data.map((item, index) => (
          <ReactionVideoItem key={index} src={item} />
        ))
      }
    </>
  );
};

export default ReactionVideoList;