export const RegisterReducer = (state, action) => {
    switch(action.type){
        case 'SET_REGISTER': 
            return {
                ...state,
                email: action.payload.email,   
                password: action.payload.password
            } 
        case 'SET_ERROR_MESSAGE': 
            return {
                ...state,
                error_message: action.payload.message
            } 
        case 'SET_PROCESS':
            return {
                ...state,
                is_processing: action.payload.is_processing
            }
        case 'RESET_EMAIL_PASSWORD':
            return {
                ...state,
                email: null,   
                password: null
            }                
        default:
            return state    
    }
}