import React from 'react';
import './ProcessMessageModal.css';

const ProcessMessageModal = ({ message }) => {
  return (
    <div className={"process-message-modal"}>
      <div className={"process-message-content-wrapper"}>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ProcessMessageModal;
